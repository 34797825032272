import { useEffect } from "react";
import {Button, Select, message } from "antd";
import { Link } from "react-router-dom";
import Layout from "../../shared/layout";
import axios from "axios";
import {Spin , Skeleton,Modal,Form,Input} from "antd";
import { useState } from "react";
import { toast } from "react-toastify"
import { useDemogarphicContext } from "../../context/demographic";
axios.defaults.baseURL = process.env.REACT_APP_API
const {Item} = Form

const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}

const DemographicHistry = ()=>{
    const [status , setStatus , demographicData , mutate,isLoading] = useDemogarphicContext()
    const [spin ,setSpin] = useState(false);
    const [value , setValue] = useState({});
    const [model,setModel] = useState(false)
    const [enrollUser , setEnrollUser] = useState()
    const [type , setType] = useState("")

    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("auth"))
        data&&data&&setEnrollUser(data.data.fullname)
       },[])

    const Toolbar = ()=>{
        return (
            <Link to="/retailer/dentry">
                <Button
                    size="large"
                    shape="round"
                    style={{borderRadius:0}} className="bg-indigo-900 text-white  mb-2">Demographic New Entry</Button>
            </Link>
        )
    }
   
    const updateData = (values)=>{
        const applyTime = new Date(values.dateofapply)
        const currentTime = new Date()
        const timeDifference = (currentTime - applyTime)/60000
        if(timeDifference >= 30)
        return  toast.info("! According to Apply time with in 30 Minutes Editable")
        setValue(values)
        setModel(true)
     Editform.setFieldsValue(values)
    }
    const onFinish = async (values)=>{
     try {
       setSpin(true) 
       const res = await axios.put(`demographic/set-finger/${value.id}`,values)
       toast.success("Data Successfully Updated")
       setModel(false)
       mutate(`/demographic/set-finger?action=${status}`)
     } catch (error) {
        toast.error("Something went wrong")
       
     }
     finally {
       setSpin(false)
     }
    }
    
    const [Editform] = Form.useForm()

    const onStatusFilter =(status)=>{
        setStatus(status)
        mutate(`/demographic/set-finger?action=${status}`)
    }

    const onType =(value)=>{
        setType(value)
      }
      const downloadPhoto = async (aadhar, fullname) => {
        try {
            const response = await axios.get(`/demographic/download/photo/retiler?aadhar=${aadhar}`);
            const data = response.data;
            const doc =  `data:image/jpeg;base64,${data.document}`
            const a = document.createElement("A");
            a.href = doc;
            a.download = `${fullname}_silip.jpg`;
            a.click();
            toast.success("Download Successfuly")
        } catch (error) {
            toast.error("Something went wrong")
        }
        
    };
      
    
    return(
        <Layout title="Demographic Update Histry" Toolbar={<Toolbar />}>
            <div className=" w-full shadow-md rounded-md p-5 border flex flex-col gap-y-5">
                <h1 className="font-semibold text-[18px] capitalize">{enrollUser&&enrollUser}</h1>
                <Spin spinning={isLoading}>
                {isLoading && <Skeleton active /> || (
        <table className="w-full border">
            <caption className="font-semibold text-2xl mb-4">Demographic Update History</caption>
            <thead>
                <tr>
                    <th className="border py-3">S.no</th>
                    <th className="border py-3">Fullname</th>
                    <th className="border py-3">Aadhar no</th>
                    <th className="border py-3">Mobile no</th>
                    <th className="border py-3">Email Id</th>
                    <th className="border py-3">Update Type</th>
                    <th className="border py-3">Created on</th>
                    <th className="border py-3">
                        Status
                        <Select className="w-full" value={status} onChange={onStatusFilter}>
                            <Select.Option value="">All data</Select.Option>
                            <Select.Option value="pending">Pending</Select.Option>
                            <Select.Option value="success">Success</Select.Option>
                            <Select.Option value="rejected">Rejected</Select.Option>
                        </Select>
                    </th>
                    <th className="border py-3">Download Slip</th>
                    <th className="border py-3">Action</th>
                </tr>
            </thead>
            {
                demographicData && demographicData.length > 0 && (
                    <tbody>
                        {
                            demographicData.map((item, index) => (
                                <tr className="odd:bg-[#c8d7f992] even:bg-pink-100 text-center" key={index}>
                                    <td className="py-3">{index + 1}</td>
                                    <td className="capitalize">{item.fullname}</td>
                                    <td>{item.aadhaarnumber}</td>
                                    <td>{item.mobile}</td>
                                    <td className="lowercase">{item.email}</td>
                                    <td className="lowercase">{item.updatetype}</td>
                                    <td>{new Date(item.dateofapply).toLocaleDateString()} {new Date(item.dateofapply).toLocaleTimeString()}</td>
                                    <td className="capitalize">
                                        <div className="flex items-center justify-center gap-3">
                                            <i className={`bx bxs-circle mt-2 ${item.action === "pending" ? "text-red-700 bx-flashing" : "text-green-700"}`}></i>
                                            <p>{item.action}</p>
                                        </div>
                                    </td>
                                    <td>
                                        {(() => {
                                            if (item.action === "pending") {
                                                return <i className='bx bx-error-alt text-2xl'></i>;
                                            } else if (item.action === "success") {
                                                return (
                                                    <button onClick={() => downloadPhoto(item.aadhaarnumber, item.fullname)}>
                                                        <i className='bx bx-down-arrow-circle bx-tada-hover text-green-600 text-2xl'></i>
                                                    </button>
                                                );
                                            } else if (item.action === "rejected") {
                                                return <i className='bx bx-x text-red-600 text-2xl'></i>;
                                            }
                                        })()}
                                    </td>
                                    <td className="lowercase">
                                        {(() => {
                                            if (item.action === "pending") {
                                                return (
                                                    <button onClick={() => updateData(item)}>
                                                        <i className='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'></i>
                                                    </button>
                                                );
                                            } else if (item.action === "success") {
                                                return <i className='bx bx-check text-green-600 text-2xl'></i>;
                                            } else if (item.action === "rejected") {
                                                return <i className='bx bx-x text-red-600 text-2xl'></i>;
                                            }
                                        })()}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                )
            }
        </table>
    )}
             
                </Spin>
                <Modal
                    title="Demographic Data Correction"
                    centered
                    open={model}
                    onCancel={() => setModel(false)}
                    width={600}
                    footer ={null}
                    style={{ maxHeight: 700, overflowY: 'auto' }}
                    
                >
                <Spin spinning = {spin}>
                <Form  encType="multipart/form-data" form={Editform} onFinish={onFinish}>
                        <div className="grid md:grid-cols-2 gap-3 ">
                            <Item
                                name="fullname"
                                label="Full Name"
                                className="flex flex-col"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please enter your full name' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Fullname*" />
                            </Item>
                            <Item name="updatetype"
                                label="Select Update Type"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please enter your full name' }]}
                              
                            >
                              <Select onChange={onType} >
                                <Select.Option value="name">Name</Select.Option>
                                <Select.Option value="gender">Gender</Select.Option>
                                <Select.Option value="dob">DOB</Select.Option>
                                <Select.Option value="address">Address&Mobile</Select.Option>
                              </Select>
                            </Item>
                            {
                              (() => {
                              if ( type === "name") {
                                 return(
                                  <Item
                                    name="fatherName"
                                    label="Father Name"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    rules={[{  message: "Please enter your father's name" }]}
                                  >
                                    <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                                  </Item>
                                 )
                              } 

                              else if (type === "gender")
                                    return(
                                      <Item
                                        name="fatherName"
                                        label="Father Name"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  message: "Please enter your father's name" }]}
                                    >
                                      <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                                    </Item>
                                    )
                              else if (type === "dob")
                                  return(
                                    <Item
                                        name="fatherName"
                                        label="Father Name"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  message: "Please enter your father's name" }]}
                                  >
                                    <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                                  </Item>
                                  )
                              
                                else if (!type === "address")
                                return(
                                  <Item
                                    name="fathername"
                                    label="Father Name"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    rules={[{  message: "Please enter your father's name" }]}
                                >
                                  <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                                </Item>
                                )
                                


                              })()
                            }
                             {
                              (() => {
                              if (type === "name") {
                                  return(
                                    <Item
                                        name="newName"
                                        label="New Name"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  message: "Please enter your father's name" }]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="New Name"/>
                                    </Item>
                                  )
                              } 
                              else if (type === "gender")
                                    return(
                                      <Item
                                          name="gender"
                                          label="Gender"
                                          wrapperCol={{span: 24}} labelCol={{span:24}}
                                          rules={[{  message: "Please enter your gender" }]}
                                      >
                                          <Input size="large" style={{borderRadius:0}} placeholder="Gender"/>
                                      </Item>
                                    )
                              else if (type === "dob")
                                  return(
                                    <Item
                                        name="dob"
                                        label="DOB"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[
                                            {  message: "Please enter your dob" },
                                            { 
                                                pattern: /^\d{2}-\d{2}-\d{4}$/, 
                                                message: 'Please enter a valid date in the format DD-MM-YYYY' 
                                            }
                                        ]}
                                        
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Date Of Birth"/>
                                    </Item>
                                  )
                                  else if (type === "address")
                                  return(
                                   <>
                                    <Item
                                        name="fathername"
                                        label="Father Name"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  
                                          message: "Please enter your fathe name",
                                         required : true, message: 'Please enter your father name'
                                         }]}
                                    >
                                      <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                                    </Item>

                                    <Item
                                      name="landmark"
                                      label="Landmark"
                                      wrapperCol={{span: 24}} labelCol={{span:24}}
                                      rules={[{  message: "Please enter your landmark"}]}
                                    >
                                      <Input size="large" style={{borderRadius:0}} placeholder="Land Mark"/>
                                    </Item>

                                    <Item
                                        name="area"
                                        label="Area/Localty"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  message: "Please enter your area/localty" }]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Area/Localty"/>
                                    </Item>

                                    <Item
                                        name="village"
                                        label="Village"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  
                                          message: "Please enter your village",
                                          required : true, message: 'Please enter your village'
                                         }]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Village"/>
                                    </Item>
                            
                                    <Item
                                        name="postoffice"
                                        label="Post Office"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  
                                          message: "Please enter your post office",
                                          required : true, message: 'Please enter your post office'
                                         }]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Post Office"/>
                                    </Item>
                                    <Item
                                        name="pincode"
                                        label="Pin Code"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  
                                          message: "Please enter your pin code",
                                          required : true, message: 'Please enter your pin code'
                                       }]}
                                      >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Pin Code"/>
                                    </Item> 
                                   </>
                                  )
                              
                              })()
                          }
                            <Item
                                name="state"
                                label="State"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please select your state' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="State"/>
                            </Item>
                            
                            <Item
                                name="district"
                                label="District"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{ required : true, message: 'Please enter your district' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="District" />
                            </Item>

                            <Item
                                name="aadhaarnumber"
                                label="Aadhaar Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                {required : true, message: 'Please enter your Aadhaar number' },
                                { pattern: /^\d{12}$/, message: 'Aadhaar number must be 12 digits' },
                                ]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Aadhaar Number"/>
                            </Item>

                            <Item
                                name="mobile"
                                label="Mobile Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                {required :true,  message: 'Please enter your mobile number' },
                                { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits' },
                                ]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Mobile Number" />
                            </Item>

                            <Item
                                name="email"
                                label="Email (Optional)"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                { message: 'Please enter your email' },
                                { type: 'email', message: 'Invalid email address' },
                                ]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Email"/>
                            </Item>

                    </div>
                    <Item>
                        <Button type="primary" htmlType="submit" className=" bg-indigo-700 float-right  text-white">Submit</Button>
                    </Item>
                 </Form>
                </Spin> 
            
                </Modal>
            </div>
      
        </Layout>
    )
}

export default DemographicHistry;