import { useState } from "react";
import { Select,Button,Modal,Form,Input,Spin,Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import Layout from "../../layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR,{mutate} from "swr";


axios.defaults.baseURL =  process.env.REACT_APP_API

const {Item} = Form
const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}


const DemographicData = ()=>{
    const [filter , setFilter] = useState("")
    const [status,setStatus] = useState("")
    const [spin ,setSpin] = useState(false);
    const [value , setValue] = useState();
    const [model,setModel] = useState(false);
    const [edit , setEdit] = useState({});
    const [updatet,setUpdatet] = useState("")
    const [aadhaar,setAadhaar] = useState("")
    const [basephoto, setBasephoto] = useState("")
    const { data:demographicData, error, isLoading } = useSWR(`/demographic/set-finger/admin?filter=${JSON.stringify(filter&&filter)}`, fetcher)
    const navigate = useNavigate()
    

   const onStateChange = (state)=>{
       setFilter(state);
    }

    const onFinish = async (value)=>{
        try {
            await axios.put(`/demographic/set-finger/admin/${edit.id}`,value)
            toast.success("Update Success")
            setModel(false)
            mutate("/demographic/set-finger/admin")

        } catch (error) {
            toast.success("Something went wrong")
           
        }
    }


    const updateData = (values) => {
        const aadhaarnumber = values.aadhaarnumber;
        setEdit({
            id: values.id,
            enrollUser: values.enrolluser,
            updatetype: values.updatetype,
            aadhaarnumber: aadhaarnumber
        });

        setUpdatet(values.updatetype);
        setAadhaar(aadhaarnumber);
        setValue(values);
        setModel(true);
        Editform.setFieldsValue(values);
    };
    

    const [Editform] = Form.useForm()
    const preview = (data) => {
        const aadhaarNumber = data.aadhaarnumber || 'null';
        const fullname = data.fullname || 'null';
        const email = data.email || 'null';
        const mobile = data.mobile || 'null';
        const dob = data.dob || 'null';
        const gender = data.gender || 'null';
        const newName = data.newname || 'null';
        navigate(`/demographicData/${aadhaarNumber}/${fullname}/${email}/${mobile}/${dob}/${gender}/${newName}`);
    };

    // const preview = (data)=>{
    //     navigate(`/demographicData/${data.aadhaarnumber}/${data.fullname}/${data.email}/${data.mobile}/${data.dob}/${data.gender}/${data.newname}`);
    // }

    const onPhotoChange = (e) => {
        const file = e.files[0];
        if (file.size > 250000)
          return toast.error("File size should be less than or equal to 250kb");
        if (file.type !== "image/jpeg" && file.type !== "image/png")
          return toast.error("File should be in JPEG or PNG format");
        
        const reader = new FileReader();
        reader.onload = (e) => {
          const basephoto = e.target.result.split(',')[1];
          setBasephoto(basephoto);
          const photoValues = {
            slipValue: basephoto
          };
          Editform.setFieldsValue(photoValues);
        };
        reader.readAsDataURL(file);
      };
   
    const downloadData = async (aadhar, fullname) => {
        try {
            const { data } = await axios.get(`/demographic/preview/download?aadhar=${aadhar}`);
            const doc = `data:application/pdf;base64,${data.document}`;
            const a = document.createElement("A");
            a.href = doc;
            a.download = `${fullname}_document.pdf`;
            a.click();
            toast.success("Download Successfuly")
        } catch (error) {
            toast.error("Something went wrong")
        }
    };

   
    const downloadForm = async (aadhar, fullname, id) => {
        try {
            const { data } = await axios.get(`/demographic/preview/downloadform?id=${id}`);
            const excludeFields = [
                'id',
                'document',
                'dateofupdate',
                'rejectedmsg',
                'fingerprint',
                'dateofapply',
                'action',
                'enrolluser',
            ];
            if (data.data.updatetype === 'name') {
                excludeFields.push('dob', 'gender','landmark','area','village','postoffice','pincode','houseno');
            }

            if (data.data.updatetype === 'dob') {
                excludeFields.push('newname', 'gender','landmark','area','village','postoffice','pincode','houseno');
            }

            if (data.data.updatetype === 'gender') {
                excludeFields.push('newname', 'dob','landmark','area','village','postoffice','pincode','houseno');
            }
            if (data.data.updatetype === 'address') {
                excludeFields.push('newname', 'dob','gender');
            }
            const filteredData = Object.entries(data.data)
                .filter(([key]) => !excludeFields.includes(key))
                .map(([key, value]) => `${key}: ${value}`)
                .join('\n');
    
            const blob = new Blob([filteredData], { type: 'text/plain' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download', `${fullname}_form.txt`);
            downloadLink.click();

            toast.success("Download Successful");
        } catch (error) {
            toast.error("Something went wrong");
        }
    };

    return (
        <Layout>
            <div className="flex md:float-right md:w-[200px] justify-end items-center gap-2 ">   
                <Select className="w-full" value={filter} onChange={onStateChange} >
                    <Select.Option value="">All State</Select.Option>
                    <Select.Option value="assam">Assam</Select.Option>
                    <Select.Option value="westbengal">West Bengal</Select.Option>
                    <Select.Option value="jharkhand">Jharkhand</Select.Option>
                    <Select.Option value="bihar">Bihar</Select.Option>
                    <Select.Option value="uttarpardesh">Up</Select.Option>
                </Select>
            </div>
           
            <table className="w-full border">
        <caption className="font-semiBolad md:text-2xl text-xl mb-4">Demographic Pending Data</caption>
            <thead>
            <tr>
                    <th className="border py-3 ">S.no</th>
                    <th className="border py-3 ">Fullname</th>
                    <th className="border py-3 max-md:hidden">Fathername</th>
                    <th className="border py-3 max-md:hidden">State</th>
                    <th className="border py-3 max-md:hidden">District</th>
                    <th className="border py-3 max-md:hidden">Aadhaarnumber</th>
                    <th className="border py-3 max-md:hidden">Mobilenumber</th>
                    <th className="border py-3 max-md:hidden">Email</th>
                    <th className="border py-3 max-md:hidden">Enroll User</th>
                    <th className="border py-3 max-md:hidden">TypeOf</th>
                    <th className="border py-3 max-md:hidden">Status</th>
                    <th className="border py-3 max-md:hidden">Created on</th>
                    <th className="border py-3 max-md:hidden">Action</th>
                    <th className="border py-3 max-md:hidden">Document</th>
                    <th className="border py-3 max-md:hidden">Form</th>
                </tr>
           
            </thead>
            <tbody>
              {
                demographicData && demographicData.map((data , index)=>(
                 <tr key={index} className="text-center">
                    <td className='py-2 '>{(index)+1}</td>
                    <td className="capitalize cursor-pointer" onClick={()=>preview(data)}>{data.fullname}</td>
                    <td className="capitalize max-md:hidden ">{data.fathername}</td>
                    <td className='max-md:hidden'>{data.state}</td>
                    <td className='max-md:hidden'> {data.district}</td>
                    <td className='max-md:hidden'> {data.aadhaarnumber}</td>
                    <td className='max-md:hidden'> {data.mobile}</td>
                    <td className='max-md:hidden lowercase'> {data.email}</td>
                    <td className='max-md:hidden lowercase'> {data.enrolluser}</td>
                    <td className='max-md:hidden'> {data.updatetype}</td>
                    <td className='max-md:hidden'> {data.action}</td>
                    <td className="max-md:hidden">{ new Date(data.dateofapply).toLocaleDateString() } {new Date(data.dateofapply).toLocaleTimeString()}</td>
                    <td className="lowercase">
                     <button  onClick={()=>updateData(data)}><i class='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'  ></i> </button>               
                    </td> 
                    <td className='max-md:hidden'>
                        <button  onClick={()=>downloadData(data.aadhaarnumber,data.fullname)}><i class='bx bx-down-arrow-circle bx-tada-hover text-2xl cursor-pointer'  ></i> </button>
                    </td>   
                    <td className='max-md:hidden'>
                        <button  onClick={()=>downloadForm(data.aadhaarnumber,data.fullname,data.id)}><i class='bx bx-down-arrow-circle bx-tada-hover text-2xl cursor-pointer'></i> </button>
                    </td>  
                    
                 </tr>
                ))
               }
            </tbody>
        </table>

            <Modal
                title="Demographic Status Update"
                centered
                open={model}
                onCancel={() => setModel(false)}
                width={300}
                footer ={null}
                style={{ maxHeight: 700, overflowY: 'auto' }}>

                    <Spin spinning = {spin}>
                    <Form  encType="multipart/form-data" form={Editform} onFinish={onFinish} initialValues={{"dateofupdate": new Date(), "updatetype": updatet, "aadhaarnumber": aadhaar}}>
                            <div className="grid md:grid-cols-2 gap-3 ">
                                <Item
                                    name="fullname"
                                    label="Full Name"
                                    className="flex flex-col"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    rules={[{  required : true, message: 'Please enter your full name' }]}>
                                    <Input style={{borderRadius:0}} placeholder="Fullname*" />
                                </Item>

                                <Item
                                    name="action"
                                    label="Status"
                                    className="flex flex-col"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    size="large"
                                >
                                <Select className="w-full" value={status}>
                                    <Select.Option value="success"> Success</Select.Option>
                                    <Select.Option value="rejected">Rejected</Select.Option>
                                </Select>
                                </Item>

                                <Item 
                                    name="reason"
                                    label="rejected reason"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="Rejected Reason" />
                                </Item>
                                <Item
                                    name="updatetype"
                                    label="Update Type"
                                    className="flex flex-col"
                                    wrapperCol={{span: 24}} 
                                    labelCol={{span:24}}
                                    size="large"
                                    style={{ display: 'none' }}
                                    initialValue={updatet}
                                >
                                   
                                </Item>

                                <Item
                                label="Photo"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                onChange={(e)=>onPhotoChange(e.target)}
                                className="md:mr-6" 
                                rules={[{  message: "Please upload poi" }]}
                            >
                                <Upload multiple={false}>
                                    <Button size="large" className="w-fit" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Item>
                            
                            <Item
                                name="slipValue"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please upload poi" }]}
                                >
                                <Input hidden placeholder="photo"  />
                            </Item>  

                                <Item
                                    name="dateofupdate"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input hidden size="large"  />
                                    
                                </Item>
                           
                                <Item 
                                    name="enrolluser"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="Enroll user" hidden/>
                                </Item>
                                <Item 
                                    name="aadhaarnumber"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="aadhaarnumber" hidden/>
                                </Item>
                            
                        </div>
                        <Item className="flex justify-center items-center">
                            <Button type="primary" htmlType="submit" className=" bg-indigo-700 float-right  text-white "> Submit</Button>
                        </Item>
                        
                    </Form>
                    </Spin> 
                
            </Modal>
        </Layout>
    )
}

export default DemographicData