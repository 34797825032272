import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Homepage from './components/admin/homepage'
import Dashboard from './components/dashboard'
import Login from './components/login'
import Register from './components/register'
import MobileEntry from './components/mobile/entry'
import MobileHistry from './components/mobile/update-histry'
import LoadMoney from './components/loadmoney'
import Dentry from './components/demographic/dentry'
import DemographicHistry from './components/demographic/dupdate-histoty'
import AdminRegister from './components/admin/adminregister'
import AdminLogin from './components/admin/adminlogin'
import AdminDashboard from './components/admin'
import Sub from './components/admin/sub'
import SubAdmin from './components/admin/subadmin'
import SubMobileData from './components/admin/subadmin/submobiledata'
import SubMobileDataHistory from './components/admin/subadmin/submobilehistory'
import SubDemographicData from './components/admin/subadmin/demographic/demographicdata'
import SubDemographicHistory from './components/admin/subadmin/demographic/demographichistory'
import SubChildData from './components/admin/subadmin/child/childdata'
import SubChildHistory from './components/admin/subadmin/child/childhistory'
import SubAdminLogin from './components/admin/subadmin/subadminlogin'
import User from './components/admin/user'
import EntryToken from './components/admin/entry-token'
import MobileData from './components/admin/mobile/mobiledata'
import MobileDataHistory from './components/admin/mobile/mobiledatahistory'
import DemographicData from './components/admin/demographic/demographicdata'
import AdminDemographicHistory from './components/admin/demographic/demographichistory'
import AdminChildData from './components/admin/child/childdata'
import AdminChildHistory from './components/admin/child/childhistory'
import ChildEntry from './components/child/childentry'
import ChildcHistry from './components/child/childhistory'
import ChildPreview from './components/admin/child/childdata/childpreview'
import NotFound from './components/notfound'
import 'boxicons/css/boxicons.min.css'
import { PrivateRoute } from './components/private-route'
import Preview from './components/admin/mobile/mobiledata/mobiledatapreview'
import DemographicPreview from './components/admin/demographic/demographicdata/demographicpreview'
import { AdminRoute } from './components/private-route/admin'
import { SubAdminRoute } from './components/private-route/subadmin'

const App = ()=>{
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/homepage" element={<Homepage />} >

        </Route>
          <Route path='/retailer/' element={<PrivateRoute />}>
          <Route path ="dashboard" element={<Dashboard />} />
          <Route path="entry" element={<MobileEntry />} />
          <Route path="update-history" element={<MobileHistry />} />
          <Route path="load-money" element={<LoadMoney />} />
          <Route path="dentry" element={<Dentry />} />
          <Route path="demo-update-history" element={<DemographicHistry />} />
          <Route path='childentry' element={<ChildEntry />} />
          <Route path='child-history' element={<ChildcHistry />} />
        </Route>

        <Route path='adminreg' element={<AdminRegister />} />

        <Route  path='adminlogin' element={<AdminLogin />} />

        <Route path = "/admin/" element={<AdminRoute />} >
          <Route path="admindashboard" element={<AdminDashboard />} />
          <Route  path='sub' element={<Sub />} />
          <Route path='user' element={<User />}/>
          <Route path='entry-token' element={<EntryToken />}/>
          <Route path='mobiledata' element={<MobileData />} />
          <Route path='mobiledatahistory' element={<MobileDataHistory />} />
          <Route path='demographic' element={<DemographicData />} />
          <Route path='demographichistory' element={<AdminDemographicHistory />} />
          <Route path='child' element={<AdminChildData />} />
          <Route path='child-history' element={<AdminChildHistory />} />
        </Route>
      <Route path='/subadminlogin' element={<SubAdminLogin />} />
        <Route path='/subadmin' element = {<SubAdminRoute />}>
        <Route path='subdashboard' element={<SubAdmin />} />
        <Route path='submobiledata' element={<SubMobileData />} />
        <Route path='submobiledatahistory' element={<SubMobileDataHistory />} />
        <Route path='subdemographicdata' element={<SubDemographicData />} />
        <Route path='subdemographichistory' element={<SubDemographicHistory />} />
        <Route path='subchilddata' element={<SubChildData />} />
        <Route path='subchildhistory' element={<SubChildHistory />} />
      </Route>

        <Route path = "/mobiledata/:email/:name/:mail/:mobile" element={<Preview />}/>
        <Route path = "/demographicdata/:email/:name/:mail/:mobile/:dob/:gender/:newname" element={<DemographicPreview />}/>
        <Route path = "/childdata/:email/:name/:mail/:mobile/:dob/:gender/:newname" element={<ChildPreview />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default App;