import { useState, useEffect } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import AdminSpinner from "./admin-spinner";
axios.defaults.baseURL = process.env.REACT_APP_API

export const AdminRoute = () => { // Renamed to start with uppercase letter

  const [ok, setOk] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [adminAuth , setAdminAuth] = useState();

  
  useEffect(() => {
   
    const data = JSON.parse(localStorage.getItem("adminAuth"))
    if(data){
      setAdminAuth(data)
    }
    
    const authCheck = async () => {
   
      try {
        const { data } = await axios.get("/private-user/admin", {
          headers: {
            Authorization: adminAuth?.token,
            role : "admin"
          },
        });
       
        if (data.success) {
          setOk(true);
        }
      } catch (error) {
        setOk(false);
        setError(error.message || "An error occurred");

      } finally {
        setLoading(false);
      }
    };
  

 adminAuth && adminAuth && authCheck()

  }, [adminAuth&&adminAuth.token]);

  return ok ? <Outlet /> : <AdminSpinner />;
};

