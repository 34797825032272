import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';


axios.defaults.baseURL = process.env.REACT_APP_API;

const DemographicPreview = () => {
    const navigate = useNavigate();
    const { email, name, mail, mobile, newname, gender, dob } = useParams();
    const [fingerImg, setFingerImg] = useState("");
    const [grayscaleLimit, setGrayscaleLimit] = useState(128);
    const [resizePercentage, setResizePercentage] = useState(100);

    useEffect(() => {
        const processImage = () => {
            const canvas = document.getElementById('outputCanvas');
            const context = canvas.getContext('2d');

            const img = new Image();
            img.onload = () => {
                const canvasWidth = (img.width * resizePercentage) / 100;
                const canvasHeight = (img.height * resizePercentage) / 100;

                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                context.drawImage(img, 0, 0, canvasWidth, canvasHeight);

                const imageData = context.getImageData(0, 0, canvasWidth, canvasHeight);
                const data = imageData.data;

                for (let i = 0; i < data.length; i += 4) {
                    const grayscaleValue = (data[i] + data[i + 1] + data[i + 2]) / 3;

                    if (grayscaleValue <= grayscaleLimit) {
                        data[i] = 255;
                        data[i + 1] = 255;
                        data[i + 2] = 255;
                    } else {
                        data[i] = 0;
                        data[i + 1] = 0;
                        data[i + 2] = 0;
                    }
                }

                context.translate(canvasWidth, 0);
                context.scale(-1, 1);

                context.putImageData(imageData, 0, 0);
            };

            img.src = `data:image/bmp;base64,${fingerImg}`;
        };

        processImage();
    }, [fingerImg, grayscaleLimit, resizePercentage]);

    const requestFinger = async (finger) => {
        try {
            const { data } = await axios.get(`/demographic/preview?aadhar=${email || ''}&index=${finger}`);
            setFingerImg(data?.fingerprint);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex min-h-screen bg-black">
            <div className="container bg-black w-[80%] md:p-20 p-4">
                <div>
                    <div>
                        <div>
                            <div className="page-header">
                                <h2 className="text-white font-semibold text-xl mb-4">
                                    Name: {name} <br />
                                    Aadhaar No: {email} <br />
                                    Mobile No: {mobile !== "null" ? mobile : ""} <br />
                                    Email Id: {mail !== "null" ? mail : ""} <br />
                                    {newname !== "null" && <span>New Name: {newname}</span>}
                                    {gender !== "null" && <span>Gender: {gender}</span>}
                                    {dob !== "null" && <span>Date of Birth: {dob}</span>}
                                </h2>
                                <div className="flex gap-2 flex-wrap m-1">
                                    <Button className="bg-[#286090] text-white hover:bg-indigo-500" style={{ color: "#fff" }} onClick={() => navigate('/admin/demographic')}>Back To Dashboard</Button>
                                    {[0, 1, 2, 3, 4].map((index) => (
                                        <Button
                                            key={index}
                                            className="bg-[#31B0D5] text-white hover:bg-indigo-500"
                                            style={{ color: "#fff" }}
                                            onClick={() => requestFinger(index + 1)}
                                        >
                                            F-Print {index + 1}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <label htmlFor="grayscaleSlider" className="flex text-[#333333]">Grayscale Value Limit:</label>
                    <div className="flex w-full justify-center gap-2">
                        <input
                            type="range"
                            id="grayscaleSlider"
                            min="0"
                            max="255"
                            step="1"
                            defaultValue={grayscaleLimit}
                            className="w-full"
                            onChange={(e) => setGrayscaleLimit(parseInt(e.target.value))}
                        />
                        <span id="sliderValue" className="text-[#333333]">{grayscaleLimit}</span>
                    </div>

                    <label htmlFor="resizeSlider" className="flex text-[#333333]">Resize Image:</label>
                    <div className="flex w-full justify-center gap-2">
                        <input
                            type="range"
                            id="resizeSlider"
                            min="5"
                            max="200"
                            step="1"
                            defaultValue={resizePercentage}
                            className="w-full"
                            onChange={(e) => setResizePercentage(parseInt(e.target.value))}
                        />
                        <span id="resizeValue" className="text-[#333333]">{resizePercentage}%</span>
                    </div>

                    <canvas id="outputCanvas" style={{ padding: '5%', transform: 'scaleX(-1)', marginLeft: '20%' }} width="316" height="354"></canvas>
                </div>
            </div>
        </div>
    );
};

export default DemographicPreview;
