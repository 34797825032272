import React, { useState } from 'react';
import { Skeleton,Switch, Modal,Form,Spin,Button,Select} from 'antd';
import Layout from '../layout';
import useSWR , {mutate} from 'swr';
import { toast } from "react-toastify";
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API

const {Item} = Form;
const fetcher = async (url)=>{
   try {
     const {data} = await axios.get(url);
     return data;
   } catch (error) {
    throw new Error(error)
   }
}

const User = () => {
    const [Editform] = Form.useForm()
    const [model,setModel] = useState(false)
    const [usertype,setSubtype] = useState("")
    const [value , setValue] = useState();
    const [spin ,setSpin] = useState(false);
    const [edit , setEdit] = useState({});
    const [PermissionForm] = Form.useForm()

    const {data , error , isloading} = useSWR ("/users" , fetcher)
	console.log(data);

    const onStatusChange = async (id , value)=>{
        try {
             await axios.put(`/users/${id}`, {status : value ? "1" : "0"})
             mutate("/users")
            
        } catch (error) {
            toast.error("Something went wrong")
        }
    }
    const updateData = (values)=>{
        setEdit({id:values.id })
        setModel(true)
     Editform.setFieldsValue(values)
     
    }
    const onPermissionFinish = async (value)=>{
        try {
            await axios.put(`/users/role/${edit.id}`,value)
            toast.success("Update Success")
            setModel(false)
            mutate("/users")

        } catch (error) {
            toast.error("Something went wrong")
           
        }
    }
  return (
    <Layout>
       {

        isloading && <Skeleton active /> || <table className="w-full border">
        <caption className='mb-2 font-semibold text-xl'>Retailer Active & Deactive </caption>
            <thead>
                <tr>
                    <th className="border py-3">S.no</th>
                    <th className="border py-3">Fullname</th>
                    <th className="border py-3">Mobile no</th>
                    <th className="border py-3">Email Id</th>
                    <th className="border py-3">Wallet</th>
                    <th className="border py-3">Status</th>
                    <th className="border py-3">Created on</th>
                    <th className="border py-3">Service Active</th>
                    <th className="border py-3">Service Update</th>
                    <th className="border py-3">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    data && data.result.map((user , index)=>(
                    <tr className=" odd:bg-[#c8d7f992]  even:bg-pink-100 text-center" key={index}>
                        <td className='py-2'>{(index)+1}</td>
                        <td className="capitalize">{user.fullname}</td>
                        <td className=''>{user.mobile}</td>
                        <td className="">{user.email}</td>
                        <td className=''>{user.wallet}</td>
                        <td className='capitalize text-start'>{user.status ? "active" : "deactive"}</td>
                        <td>{ new Date(user.createdat).toLocaleDateString() } {new Date(user.createdat).toLocaleTimeString()}</td>
                        <td className="">{user.usertype}</td>
                        <td className="lowercase">
                            <button  onClick={()=>updateData(user)}><i class='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'  ></i> </button>               
                        </td>
                        <td><Switch defaultChecked={user.status}  onChange={(value)=>onStatusChange(user.id , value)} /></td>
                    </tr>

                    ))
                }   
            </tbody>
        </table>
}   
     {/* Premission update model */}
     <Modal
              centered
              open={model}
              onCancel={() => setModel(false)}
              width={400}
              footer ={null}
              style={{ maxHeight: 300, overflowY: 'auto' }}
              
            >
                
                <div className="bg-white  rounded-lg border-0 w-12/12 p-8  gap-y-4   animate__animated animate__zoomIn animate_faster">
                    <div className="flex justify-center items-center ">
                        <h className="text-2xl font-semibold">User Service Update</h>
                    </div>
                <Spin spinning={spin}>
                        <Form onFinish={onPermissionFinish} form={PermissionForm} >
                        <Item
                            name="action"
                            label="Role"
                            className="flex flex-col"
                            wrapperCol={{span: 24}} labelCol={{span:24}}
                            size="large"
                        >
                            <Select className="w-full"  value={usertype} >
                                <Select.Option value="mobile">Mobile</Select.Option>
                                <Select.Option value="modemographic">Mobile-Demographic</Select.Option>
                                <Select.Option value="modechild">Mobile-Demographic-Child</Select.Option>
                            </Select>
                        </Item>
                            <Item>
                                <Button
                                    htmlType="submit" 
                                    size="large"
                                    style={{borderRadius:0}} className="w-full bg-indigo-900 text-white" >Submit</Button>
                            </Item>
                        
                        </Form>
                    </Spin>
                </div>
        </Modal>
    </Layout>
  );
};

export default User;