import Layout from "../layout";


const Homepage = ()=>{
    return(
        <Layout>
            <h1>aiiiiii</h1>
        </Layout>
    )
}

export default Homepage;