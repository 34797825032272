import { useState,useEffect } from "react";
import { Avatar, Button, Layout,Menu , Drawer} from "antd";
import { DashboardOutlined ,
    DollarOutlined,
    MobileOutlined,
    HistoryOutlined,
    CloudDownloadOutlined,
    LogoutOutlined,
    MenuFoldOutlined,UserOutlined,TableOutlined,WhatsAppOutlined } from '@ant-design/icons';
import { useNavigate} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../../context/auth";
const {Sider,Content,Header} = Layout;



const LayoutEl = ({children,title="",Toolbar=null,mess=""})=>{
    const [open,setOpen] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const [auth ,setAuth] = useAuth()

    const [enrollUser , setEnrollUser] = useState()
    const [openDrawer, setOpenDrawer] = useState(false);
   
  
    const showDrawer = () => {
      setOpenDrawer(true);
    };
  
    const onClose = () => {
      setOpenDrawer(false);
    };
 
    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("auth"))
        data&&data&&setEnrollUser(data.data.fullname)
       },[])

    const logOut = ()=>{
        localStorage.removeItem("auth");
        navigate("/")
        setAuth("")
    }

   const mantraDownload = ()=>{
     const a = document.createElement("A")
     a.href = "/driver/MFS100Driver_9.2.0.0.exe"
     a.download = "MFS100Driver_9.2.0.0.exe"
     a.click()
   } 
   
   const mantraRdService = ()=>{
    const a = document.createElement("A")
    a.href = "/driver/MantraRDService_1.0.8.exe"
    a.download = "MantraRDService_1.0.8.exe"
    a.click()
  } 
  
  const mantra100Client = ()=>{
    const a = document.createElement("A")
    a.href = "/driver/MFS100ClientService_9.0.3.8.exe"
    a.download = "MFS100ClientService_9.0.3.8.exe"
    a.click()
  } 

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

 
    const items = [
        {
            label: <p onClick={()=>navigate('/retailer/dashboard')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/retailer/dashboard" ? "bg-blue-500 text-white" : null}`}> <DashboardOutlined />Dashboard</p>,
            path: '/dashboard',
            key : "1"
            
        },
        {
            label: <p onClick={()=>navigate('/retailer/load-money')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/retailer/load-money" ? "bg-blue-500 text-white" : null}`}> <DollarOutlined/> Load Maney & History</p>,
            path: '/retailer/load-money',
            key : "2"

        },
        {
            label: <p className="flex items-center gap-x-3"> <MobileOutlined /> Mobile Management </p>,
            path: '/#',
            key : "3",
            children : [
                {
                    label: <p onClick={()=>navigate('/retailer/entry')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/retailer/entry" ? "bg-blue-500 text-white" : null}`}> <TableOutlined /> Mobile Update New Entry</p>,
                    path: '/entry',
                    key : "4"
                },
                {
                    label: <p onClick={()=>navigate('/retailer/update-history')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/retailer/update-history" ? "bg-blue-500 text-white" : null}`}><HistoryOutlined />Mobile Update Hsitry</p>,
                    path: '/retailer/update-history',
                    key : "5"
                    
                }
            ]
        },
        {
            label: <p className="flex items-center gap-x-3"> <MobileOutlined /> Demographic Management </p>,
            path: '/#',
            key : "6",
            children : [
                {
                    label: <p onClick={()=>navigate('/retailer/dentry')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/retailer/dentry" ? "bg-blue-500 text-white" : null}`}> <TableOutlined /> Demographic Update New Entry</p>,
                    path: '/dentry',
                    key : "7"
                    
                },
                {
                    label: <p onClick={()=>navigate('/retailer/demo-update-history')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === '/retailer/demo-update-history' ? "bg-blue-500 text-white" : null}`}><HistoryOutlined />Demographic Update Hsitry</p>,
                    path: '/retailer/demo-update-history',
                    key : "8"
                    
                }
            ]
        },
         {
            label: <p className="flex items-center gap-x-3"> <UserOutlined />Child Management </p>,
            path: '/#',
            key : "9",
            children : [
                {
                    label: <p onClick={()=>navigate('/retailer/childentry')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/retailer/childentry" ? "bg-blue-500 text-white" : null}`}> <TableOutlined />Child New Entry</p>,
                    path: '/childentry',
                    key : "10"
                    
                },
                {
                    label: <p onClick={()=>navigate('/retailer/child-history')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === '/retailer/child-history' ? "bg-blue-500 text-white" : null}`}><HistoryOutlined />Child Enroll History</p>,
                    path: '/retailer/child-history',
                    key : "11"
                    
                }
            ]
        },
        {
            label: <p  className={`px-3 rounded-md flex items-center gap-x-3 text-white" `}><CloudDownloadOutlined /> Download Driver</p>,
            path: '/#',
            key : "12",
            children : [
            
                    {
                        label: <p onClick={()=>mantraDownload()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MFS100Driver_9.2.0.0</p>,
                         key : "13"
                        
                    },
                    {
                        label: <p onClick={()=>mantraRdService()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MantraRDService_1.0.8</p>,
                         key : "14"
                        
                    },
                    {
                        label: <p onClick={()=>mantra100Client()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MFS100ClientService_9.0.3.8</p>,
                         key : "15"
                        
                    },
            
            ]
            
        },
        {
            label: <p onClick={logOut} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/signout" ? "bg-blue-500 text-white" : null}`}> <LogoutOutlined /> Logout</p>,
            path: '/signout',
            key : "16"
            
        } 
    ]
   
    
    return (
        <Layout>

            {/* for mobile*/}
            <Drawer
                title={<h1 className="text-2xl">{enrollUser && enrollUser}</h1>}
                placement="left"
                closable={true}
                onClose={onClose}
                open={openDrawer}
            >
         <Menu 
            theme="light" 
            className="flex flex-col gap-2 py-4"
            mode="inline"
            items={items}
            inlineCollapsed={collapsed}>     
        </Menu>
        
      </Drawer>
    <Sider trigger={null} className="h-[100vh] overflow-auto force-visible-vertical custom-scrollbar fixed max-md:hidden" width="18%" collapsible collapsed={open} style={{ position: "fixed" }}>
        <div className="flex flex-col items-center justify-center py-4 gap-4">
            <h1 className="flex text-xl text-white w-full justify-center items-center">Aadhaar Correction</h1>
            <Avatar size={64} icon={<UserOutlined />} className="bg-red-500" />
            <h1 className="flex text-semibold text-white w-full justify-center items-center capitalize">{enrollUser && enrollUser}</h1>
        </div>
         <h1 className="flex text-xl text-white w-full justify-center items-center">Our Services</h1>
        <div className="h-[500px] w-full  overflow-y-auto">
    
            <Menu
                theme="dark"
                className="flex flex-col gap-2 py-4"
                mode="inline"
                items={items}
            >
            </Menu>
        </div>
    </Sider>

            
            <Layout  className="md:pl-[18%]">
                <div className="flex flex-col sticky top-0 z-40">
                    <Header className="bg-white items-center px-6">
                        <div  className="flex justify-between items-center">
                            <div>
                                <Button className="max-md:hidden border-0" icon={<MenuFoldOutlined />}  />
                                <Button className="md:hidden border-0" onClick={()=>showDrawer()} icon={<MenuFoldOutlined />}  />
                            </div>
                            <div className="flex justify-center items-cente" >
                              {/*  <Button  size="large" className="flex justify-center items-center border-0" icon={<WhatsAppOutlined />}>
                                    <h1 className="flex justify-center items-center  text-green-600 text-2xl">+91 8617036716</h1>
                                </Button> 
				*/}

                                <h1 className="flex justify-center items-center  text-green-600 text-2xl">Avi Shrif Mobile Number Update Ka Kam Kijiya Sablog </h1>
                                {/* <h1 className="flex justify-center items-center  text-green-600 text-2xl">Child New Enroll Fully Working Now ( All Service Working )</h1>*/}
				{/* <h1 className="flex justify-center items-center  text-green-600 text-2xl">( All Service Working ) Child New Enroll Problem Slove Last Update 07-06-2024 (Entry Now)</h1>*/}
				{/* <h1 className="flex justify-center items-center  text-green-600 text-2xl">Name, Gender, DOB, Child New Data Prossing Time 7day to 10day</h1>*/}
                                
                            </div>
                            <div>
                                <Avatar />
                            </div>
                        </div>
                    </Header>
                    <Header className="flex justify-between bg-rose-400 items-center px-6">
                        <div>
                            <h1 className="text-xl text-white">{title}</h1>
                        </div>
                        <div>
                            <h1 className="text-xl text-white">{mess&&mess}</h1>
                        </div>
                        <div>
                            {
                                Toolbar && Toolbar
                            }
                        </div>
                    </Header>
                </div>
                <Content className="p-8">
                    <ToastContainer />
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutEl;