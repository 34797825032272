import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'animate.css';
import {MobileContext} from './components/context/mobile'
import { AuthProvider } from './components/context/auth';
import { AuthSubAdmin } from './components/context/subAdminAuth';
import { DemographicContext } from './components/context/demographic';
import { ChildContext } from './components/context/child';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<AuthSubAdmin>
<AuthProvider >
  <MobileContext>
    <DemographicContext>
      <ChildContext>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ChildContext>
    </DemographicContext>
 </MobileContext>
 </AuthProvider>
</AuthSubAdmin>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
