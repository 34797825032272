import {useEffect , useState} from 'react';
import Layout from "../shared/layout";
import { useMobileContext } from "../context/mobile";
import { useDemogarphicContext } from '../context/demographic';
import { useChildContext } from '../context/child';
import { useAuth } from '../context/auth';
import useSWR ,{mutate}from 'swr';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API

const fetcher = async (url)=>{
    try {
        const {data:{result:[user]}} = await axios.get(url);
    
        return user
    } catch (error) {
       throw new Error(error)
    }
   }
const Dashboard = ()=>{
    const [enrollUser , setEnrollUser] = useState()
    const [successDa, setSuccessDa] = useState([]);
    const [rejectedDa, setRejectedDa] = useState([]);
    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("auth"))
        data&&data&&setEnrollUser(data.data.email)
       },[])

    const [status , setStatus , mobileData ,mutate , isLoading] = useMobileContext();
    const [ ,  , demographicData , ,] = useDemogarphicContext()
    const [, ,childData , ,] = useChildContext()
    const [auth , setAuth] = useAuth();

    const{data}  = useSWR(`/users?email=${enrollUser&&enrollUser}`, fetcher)
    let successData = mobileData&&mobileData.filter(data => data.action === "success");
    let rejectedData = mobileData&&mobileData.filter(data => data.action === "rejected");

    useEffect(() => {
        if (demographicData && demographicData.length > 0) {
          setSuccessDa(demographicData.filter(data => data.action === 'success'));
          setRejectedDa(demographicData.filter(data => data.action === 'rejected'));
        } else {
          setSuccessDa([]);
          setRejectedDa([]);
        }
      }, [demographicData]);

    let successDat = childData&&childData.filter(data => data.action === "success" );
    let rejectedDat = childData&&childData.filter(data => data.action === "rejected" )


    return(
        <Layout>
            <div>

                <div className=" flex flex-col gap-y-10">
                    {/*Demographic*/}
                    <div className="flex py- gap-y-2 flex-col">
                        <p className="font-semibold text-xl">Demographic System</p>
                        <hr className="h-[1px] bg-slate-300" />
                    </div>
                    <div className="grid md:grid-cols-4  gap-10">
                    <div className="bg-gradient-to-r from-blue-500 to-blue-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center">
                        <p className="text-xl font-semibold text-white">Demographic Token</p>
                        <p className="text-3xl font-semibold text-white"> ₹ {data && data.wallet}</p>
                    </div>
                    <div className="bg-gradient-to-r from-pink-500 to-pink-300 rounded-sm py-5 px-3 flex flex-col justify-center items-center"> 
                        <p className="text-xl font-semibold text-white">Total Application</p>
                        <p className="text-3xl font-semibold text-white">{demographicData&&demographicData.length}</p>
                    </div> 
                    <div className="bg-gradient-to-r from-green-500 to-green-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center">
                            <p className="text-xl font-semibold text-white">Complete</p>
                            <p className="text-3xl font-semibold text-white">{successDa&&successDa.length}</p>
                        </div>
                    <div className="bg-gradient-to-r from-amber-500 to-amber-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center"> 
                        <p className="text-xl font-semibold text-white">Rejected</p>
                        <p className="text-3xl font-semibold text-white">{rejectedDa&&rejectedDa.length}</p>
                    </div>
                    </div>

                        {/*Mobile*/}
                    <div className="flex py- gap-y-2 flex-col">
                        <p className="font-semibold text-xl">Mobile System</p>
                        <hr className="h-[1px] bg-slate-300" />
                    </div>
                    <div className="grid md:grid-cols-4 gap-10">
                    <div className="bg-gradient-to-r from-blue-500 to-blue-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center">
                        <p className="text-xl font-semibold text-white">Wallet</p>
                        <p className="text-3xl font-semibold text-white"> ₹ {data && data.wallet}</p>
                    </div>
                    <div className="bg-gradient-to-r from-pink-500 to-pink-300 rounded-sm py-5 px-3 flex flex-col justify-center items-center"> 
                        <p className="text-xl font-semibold text-white">Total Application</p>
                        <p className="text-3xl font-semibold text-white">{mobileData && mobileData.length}</p>
                    </div> 
                    <div className="bg-gradient-to-r from-green-500 to-green-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center">
                        <p className="text-xl font-semibold text-white">Complete</p>
                        <p className="text-3xl font-semibold text-white">{successData && successData.length}</p>
                    </div>
                    <div className="bg-gradient-to-r from-amber-500 to-amber-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center"> 
                        <p className="text-xl font-semibold text-white">Rejected</p>
                        <p className="text-3xl font-semibold text-white">{rejectedData && rejectedData.length}</p>
                    </div>
                    </div>

                    {/*Child*/}

                    <div className="flex py- gap-y-2 flex-col">
                        <p className="font-semibold text-xl">Child System</p>
                        <hr className="h-[1px] bg-slate-300" />
                    </div>
                    <div className="grid md:grid-cols-4 gap-10">
                    <div className="bg-gradient-to-r from-blue-500 to-blue-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center">
                        <p className="text-xl font-semibold text-white">Wallet</p>
                        <p className="text-3xl font-semibold text-white"> ₹ {data && data.wallet}</p>
                    </div>
                    <div className="bg-gradient-to-r from-pink-500 to-pink-300 rounded-sm py-5 px-3 flex flex-col justify-center items-center"> 
                        <p className="text-xl font-semibold text-white">Total Application</p>
                        <p className="text-3xl font-semibold text-white">{childData && childData.length}</p>
                    </div> 
                    <div className="bg-gradient-to-r from-green-500 to-green-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center">
                        <p className="text-xl font-semibold text-white">Complete</p>
                        <p className="text-3xl font-semibold text-white">{successDat && successDat.length}</p>
                    </div>
                    <div className="bg-gradient-to-r from-amber-500 to-amber-400 rounded-sm py-5 px-3 flex flex-col justify-center items-center"> 
                        <p className="text-xl font-semibold text-white">Rejected</p>
                        <p className="text-3xl font-semibold text-white">{rejectedDat && rejectedDat.length}</p>
                    </div>
                    </div>

                </div>

      
             
              </div>
        </Layout>
      
    )
}

export default Dashboard;