import { useState,useEffect,useReducer } from "react";
import { Avatar, Button, Layout,Menu} from "antd";
import {
    MobileOutlined,
    HistoryOutlined,
    CloudDownloadOutlined,
    LogoutOutlined,
    MenuFoldOutlined,UserOutlined,TableOutlined} from '@ant-design/icons';
import { useNavigate} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useSubAdminAuth } from "../../../context/subAdminAuth";
const {Sider,Content,Header} = Layout;



const LayoutEl = ({children,title="",Toolbar=null})=>{
    const [open,setOpen] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
   // const [subAdminAuth] = useSubAdminAuth()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const [subAdmin , setSubAdmin] = useSubAdminAuth()
    const [subadmintype,setSubadmintype] = useState()
    const [enrollUser , setEnrollUser] = useState()
    
    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("subadminAuth"))
        data&&data&&setEnrollUser(data.data.fullname)
        data&&data&&setSubadmintype(data.data.subtype)
    },[])

    console.log(subadmintype);
    const paddingReducer = (state, action) => {
        if (action == 18) {
          return 5;
        } else {
            
          return 18;
        }
      };
      
      const [padding, setPadding] = useReducer(paddingReducer, 18);

    const mantraDownload = ()=>{
        const a = document.createElement("A")
        a.href = "/driver/MFS100Driver_9.2.0.0.exe"
        a.download = "MFS100Driver_9.2.0.0.exe"
        a.click()
      } 
      
      const mantraRdService = ()=>{
       const a = document.createElement("A")
       a.href = "/driver/MantraRDService_1.0.8.exe"
       a.download = "MantraRDService_1.0.8.exe"
       a.click()
     } 
     
     const mantra100Client = ()=>{
       const a = document.createElement("A")
       a.href = "/driver/MFS100ClientService_9.0.3.8.exe"
       a.download = "MFS100ClientService_9.0.3.8.exe"
       a.click()
     }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const subadminlogout = ()=>{
    localStorage.removeItem("subadminAuth");
    setSubAdmin("")
    navigate("/subadminlogin")

  }
    // const items = [
    //     {
    //         label: <p className="flex items-center gap-x-3"> <MobileOutlined />Data Management </p>,
    //         path: '/#',
    //         key : "1",
    //         children : [
    //             {
    //                 label: <p onClick={()=>navigate('/subadmin/submobiledata')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/submobiledata" ? "bg-blue-500 text-white" : null}`}> <TableOutlined /> Mobile Update Data</p>,
    //                 path: '/subadmin/submobiledata',
    //                 key : "2"
                    
    //             },
    //             {
    //                 label: <p onClick={()=>navigate('/subadmin/submobiledatahistory')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/submobiledatahistory" ? "bg-blue-500 text-white" : null}`}><HistoryOutlined />Mobile Data Hsitry</p>,
    //                 path: '/subadmin/submobiledatahistory',
    //                 key : "3"
                    
    //             }
    //         ]
    //     },
    //     {
    //         label: <p  className={`px-3 rounded-md flex items-center gap-x-3 text-white" `}><CloudDownloadOutlined /> Download Driver</p>,
    //         path: '/#',
    //         key : "4",
    //         children : [
            
    //                 {
    //                     label: <p onClick={()=>mantraDownload()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MFS100Driver_9.2.0.0</p>,
    //                      key : "5"
                        
    //                 },
    //                 {
    //                     label: <p onClick={()=>mantraRdService()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MantraRDService_1.0.8</p>,
    //                      key : "6"
                        
    //                 },
    //                 {
    //                     label: <p onClick={()=>mantra100Client()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MFS100ClientService_9.0.3.8</p>,
    //                      key : "7"
                        
    //                 },
            
    //         ]
            
    //     },
    //     {
    //         label: <p onClick={()=>subadminlogout()} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadminlogin" ? "bg-blue-500 text-white" : null}`}> <LogoutOutlined /> Logout</p>,
    //         path: '/subadminlogin',
    //         key : "8"
            
    //     } 
    // ]
   
    let items = [];
    if (subadmintype === "mobile") {
        items = [
            {
                label: (
                    <p className="flex items-center gap-x-3">
                        <MobileOutlined /> Data Management
                    </p>
                ),
                key: "1",
                children: [
                    {
                        label: (
                            <p
                                onClick={() => navigate('/subadmin/submobiledata')}
                                className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/submobiledata" ? "bg-blue-500 text-white" : ""}`}
                            >
                                <TableOutlined /> Mobile Update Data
                            </p>
                        ),
                        key: "2",
                    },
                    {
                        label: (
                            <p
                                onClick={() => navigate('/subadmin/submobiledatahistory')}
                                className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/submobiledatahistory" ? "bg-blue-500 text-white" : ""}`}
                            >
                                <HistoryOutlined /> Mobile Data History
                            </p>
                        ),
                        key: "3",
                    },
                ],
            },
            {
                label: (
                    <p className="flex items-center gap-x-3">
                        <CloudDownloadOutlined /> Download Driver
                    </p>
                ),
                key: "4",
                children: [
                    {
                        label: (
                            <p
                                onClick={() => mantraDownload()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100Driver_9.2.0.0
                            </p>
                        ),
                        key: "5",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantraRdService()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MantraRDService_1.0.8
                            </p>
                        ),
                        key: "6",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantra100Client()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100ClientService_9.0.3.8
                            </p>
                        ),
                        key: "7",
                    },
                ],
            },
            {
                label: (
                    <p
                        onClick={() => subadminlogout()}
                        className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadminlogin" ? "bg-blue-500 text-white" : ""}`}
                    >
                        <LogoutOutlined /> Logout
                    </p>
                ),
                key: "8",
            },
        ];
    }

    else if (subadmintype === "demographic") {
        items = [
            {
                label: (
                    <p className="flex items-center gap-x-3">
                        <MobileOutlined />Demog Management
                    </p>
                ),
                key: "9",
                children: [
                    {
                        label: (
                            <p
                                onClick={() => navigate('/subadmin/subdemographicdata')}
                                className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/subdemographicdata" ? "bg-blue-500 text-white" : ""}`}
                            >
                                <TableOutlined />Demographic Update Data
                            </p>
                        ),
                        key: "10",
                    },
                    {
                        label: (
                            <p
                                onClick={() => navigate('/subadmin/subdemographichistory')}
                                className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/subdemographichistory" ? "bg-blue-500 text-white" : ""}`}
                            >
                                <HistoryOutlined /> Demographic Update History
                            </p>
                        ),
                        key: "11",
                    },
                ],
            },
            {
                label: (
                    <p className="flex items-center gap-x-3">
                        <CloudDownloadOutlined /> Download Driver
                    </p>
                ),
                key: "12",
                children: [
                    {
                        label: (
                            <p
                                onClick={() => mantraDownload()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100Driver_9.2.0.0
                            </p>
                        ),
                        key: "13",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantraRdService()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MantraRDService_1.0.8
                            </p>
                        ),
                        key: "14",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantra100Client()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100ClientService_9.0.3.8
                            </p>
                        ),
                        key: "15",
                    },
                ],
            },
            {
                label: (
                    <p
                        onClick={() => subadminlogout()}
                        className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadminlogin" ? "bg-blue-500 text-white" : ""}`}
                    >
                        <LogoutOutlined /> Logout
                    </p>
                ),
                key: "16",
            },
        ];
    }

    else if (subadmintype === "child") {
        items = [
            {
                label: (
                    <p className="flex items-center gap-x-3">
                        <MobileOutlined />Chlid Management
                    </p>
                ),
                key: "17",
                children: [
                    {
                        label: (
                            <p
                                onClick={() => navigate('/subadmin/subchilddata')}
                                className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/subchilddata" ? "bg-blue-500 text-white" : ""}`}
                            >
                                <TableOutlined />Child Enroll Data
                            </p>
                        ),
                        key: "18",
                    },
                    {
                        label: (
                            <p
                                onClick={() => navigate('/subadmin/subchildhistory')}
                                className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadmin/subchildhistory" ? "bg-blue-500 text-white" : ""}`}
                            >
                                <HistoryOutlined /> Child Enroll History
                            </p>
                        ),
                        key: "19",
                    },
                ],
            },
            {
                label: (
                    <p className="flex items-center gap-x-3">
                        <CloudDownloadOutlined /> Download Driver
                    </p>
                ),
                key: "20",
                children: [
                    {
                        label: (
                            <p
                                onClick={() => mantraDownload()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100Driver_9.2.0.0
                            </p>
                        ),
                        key: "21",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantraRdService()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MantraRDService_1.0.8
                            </p>
                        ),
                        key: "22",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantra100Client()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100ClientService_9.0.3.8
                            </p>
                        ),
                        key: "23",
                    },
                ],
            },
            {
                label: (
                    <p
                        onClick={() => subadminlogout()}
                        className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadminlogin" ? "bg-blue-500 text-white" : ""}`}
                    >
                        <LogoutOutlined /> Logout
                    </p>
                ),
                key: "24",
            },
        ];
    }

    else if (subadmintype === "n") {
        items = [
            
            {
                label: (
                    <p className="flex items-center gap-x-3">
                        <CloudDownloadOutlined /> Download Driver
                    </p>
                ),
                key: "25",
                children: [
                    {
                        label: (
                            <p
                                onClick={() => mantraDownload()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100Driver_9.2.0.0
                            </p>
                        ),
                        key: "26",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantraRdService()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MantraRDService_1.0.8
                            </p>
                        ),
                        key: "27",
                    },
                    {
                        label: (
                            <p
                                onClick={() => mantra100Client()}
                                className="px-3 rounded-md flex items-center gap-x-3"
                            >
                                MFS100ClientService_9.0.3.8
                            </p>
                        ),
                        key: "28",
                    },
                ],
            },
            {
                label: (
                    <p
                        onClick={() => subadminlogout()}
                        className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/subadminlogin" ? "bg-blue-500 text-white" : ""}`}
                    >
                        <LogoutOutlined /> Logout
                    </p>
                ),
                key: "29",
            },
        ];
    }
    return (
        <Layout>
            <Sider trigger={null} className="min-h-screen overflow-auto force-visible-vertical custom-scrollbar" width="18%" collapsible collapsed={open} style={{position:"fixed"}}>
                <div className="flex flex-col items-center justify-center py-4 gap-4">
                    <h1 className="flex text-xl text-white w-full justify-center items-center">Aadhaar Updateion</h1>
                    <Avatar size={64} icon={<UserOutlined />} className="bg-red-500"/>
                    <h1  className="flex text-semibold text-white w-full justify-center items-center capitalize">{enrollUser&&enrollUser}</h1>
                </div>
                <h1 className="flex text-xl text-white w-full justify-center items-center">Our Services</h1>
                <Menu 
                    theme="dark" 
                    className="flex flex-col gap-2 py-4"
                    mode="inline"
                    items={items}
                >
                
              </Menu>
            </Sider>
            
            <Layout style={{paddingLeft: `${padding}%`}}>
            <div className="flex flex-col sticky top-0 z-40">
                <Header className="bg-white items-center px-6">
                    <div  className="flex justify-between items-cente">
                        <div>
                            <Button onClick={()=>{return(setOpen(!open),setPadding(padding))}} icon={<MenuFoldOutlined />} className="border-0" />
                        </div>
                        <div>
                            <Avatar />
                        </div>
                    </div>
                </Header>
                <Header className="flex justify-between bg-rose-400 items-center px-6">
                    <div>
                        <h1 className="text-xl text-white">{title}</h1>
                    </div>
                    <div>
                        {
                           Toolbar && Toolbar
                        }
                    </div>
                </Header>
                </div>
                <Content className="p-8">
                    <ToastContainer />
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutEl;