import { useEffect, useState,useRef } from "react"
import { Form, Input,Button, message ,Upload, Spin } from "antd";
import { Link } from "react-router-dom";
import Layout from "../../shared/layout";
import { useAuth } from '../../context/auth';
import $ from 'jquery'
import { toast } from "react-toastify";
import axios from  "axios";
axios.defaults.baseURL = process.env.REACT_APP_API

const {Item} = Form

class MFS100Request {
    constructor(BiometricArray) {
    this.Biometrics = BiometricArray;
    }
  }


const Demo = ()=>{
    const [uri] = useState("https://localhost:8003/mfs100/");
    const [aadhaarForm ] = Form.useForm()
    const [finger , setFinger] = useState("");
    const [fingerImage , setFingerImage] = useState("/images/demo-fingerprint.jpg");
    const [fingerValue , setFingerValue] = useState(null);
    const [allImages , setAllImages] = useState([])
    const [imageSource, setImageSource] = useState(null);
    const [auth , setAuth] = useAuth()
    const [spin , setSpin] = useState(false)
const[urserEmail , setUsewrEmail] = useState(null)

    useEffect(()=>{ 
        const data = JSON.parse(localStorage.getItem("auth"))
        data&&data&&setUsewrEmail(data.data.email)
       },[])
   
  const onFinish = async (values) => {
      
    const formData = new FormData();
        
        // for (let data in values){
        //     console.log(data,values[data]);
        //     formData.append(data,values[data])
        // }
try {
          setSpin(true)
          const {data} = await axios.post('/mobile/set-finger', values ,{
            headers : {email : urserEmail }
          });

           if(data.success)
           {
            toast.success(<p className="capitalize">{data.message}</p>)
            aadhaarForm.resetFields()
            setFingerValue(null)
           }

          } 
          catch (error) {
		console.log(error)
          toast.error(<p className="capitalize">{error.response.data.message}</p>)
          }
          
          finally{
            setSpin(false)
          }
    
        };

  const currentDate = ()=>{
        const currentDate = new Date().toLocaleString();
          return currentDate;
        }

        //sdk

  const getMFS100Info = () => 
    {
      return getMFS100Client("info");
    }

  const getMFS100KeyInfo = (key) => 
    {
      var MFS100Request = {
        "Key": key,
      };
      const jsondata = JSON.stringify(MFS100Request);
      return postMFS100Client("keyinfo", jsondata);
    }

   const captureFinger = (quality, timeout) => 
   {
      var MFS100Request = {
        "Quality": quality,
        "TimeOut": timeout
      };

      var jsondata = JSON.stringify(MFS100Request);
      return postMFS100Client("capture", jsondata);
    }

  const captureMultiFinger = (quality, timeout, noOfFinger) => 
    {
      var MFS100Request = {
        "Quality": quality,
        "TimeOut": timeout,
        "NoOfFinger": noOfFinger
      };
      const jsondata = JSON.stringify(MFS100Request);
      return postMFS100Client("capturewithdeduplicate", jsondata);
    }

  const verifyFinger = (probFMR, galleryFMR) => 
  {
    var MFS100Request = {
      "ProbTemplate": probFMR,
      "GalleryTemplate": galleryFMR,
      "BioType": "FMR"
    };
    var jsondata = JSON.stringify(MFS100Request);
    return postMFS100Client("verify", jsondata);
  }

  const matchFinger = (quality, timeout, galleryFMR) => {
    var MFS100Request = {
      "Quality": quality,
      "TimeOut": timeout,
      "GalleryTemplate": galleryFMR,
      "BioType": "FMR"
    };
    var jsondata = JSON.stringify(MFS100Request);
    return postMFS100Client("match", jsondata);
  }

  const getPidData = (biometricArray) => {
    var req = new MFS100Request(biometricArray);
    const jsondata = JSON.stringify(req);
    return postMFS100Client("getpiddata", jsondata);
  }

  const getRbdData = (biometricArray) => {
    var req = new MFS100Request(biometricArray);
    const jsondata = JSON.stringify(req);
    return postMFS100Client("getrbddata", jsondata);
  }

  const postMFS100Client = (method, jsonData) => {
    var res;
    $.support.cors = true;
    var httpStaus = false;
    $.ajax({
        type: "POST",
        async: false,
        crossDomain: true,
        url: uri + method,
        contentType: "application/json; charset=utf-8",
        data: jsonData,
        dataType: "json",
        processData: false,
        success: function (data) {
            httpStaus = true;
            res = { httpStaus: httpStaus, data: data };
        },
        error: function (jqXHR, ajaxOptions, thrownError) {
            res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
        },
    });
    return res;
  }

  const getMFS100Client = (method) => {
    let res;
    const httpStatus = false;

    fetch(uri + method)
    .then(response => response.json())
    .then(data => {
      res = { httpStatus: true, data: data };
    })
    .catch(error => {
      res = { httpStatus: false, err: getHttpError(error) };
    });

    return res;
  }

  const getHttpError = (error) => {
    let err = "Unhandled Exception";
    if (error.message === 'Failed to fetch') {
      err = 'Network request failed';
    }
    // ... (Other error handling logic)
    return err;
  }
      //end sdk



    const Toolbar = ()=>{
        return (
            <Link to="/retailer/update-history" >
                <Button
                    size="large"
                    shape="round"
                    type="text"
                    style={{borderRadius:0,}} className="bg-[#4096FF] text-white textHoverBg:text-white mb-2" >
                    Mobile Update History
                </Button>
            </Link>
        )
    }

    const  onChange = (date, dateString) => 
      {
        aadhaarForm.setFieldsValue({ dateApply: dateString });
      }

    const onCapture = (index) => 
    {
        setFinger(index);
        setTimeout(async function () {
          var quality = 60;
          var timeout = 10;
          var res =  captureFinger(quality, timeout);
          if(!res) return false
          setFinger(false);
      
          setFingerImage((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = {
              ...updatedImages[index],
              data: "data:image/bmp;base64," + res.data.BitmapData, 
            };
            return updatedImages;
          });
{/*
                  // Decoding the Base64-encoded image data
        const decodedData = atob(res.data.BitmapData);

        // Creating a Uint8Array from the decoded binary data
        const uint8Array = new Uint8Array(decodedData.length);
        uint8Array.set(Uint8Array.from(decodedData, (char) => char.charCodeAt(0)));

        console.log(uint8Array);

        // Creating a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: 'image/png' });
        console.log(blob);

        // Creating a data URL from the Blob
        const dataUrl = URL.createObjectURL(blob);
        console.log(dataUrl);
        */}
         
          setFingerValue((prevValues) => {
            const updatedValues = { ...prevValues };
            updatedValues[index] = {
              ...updatedValues[index],
              data: res.data.BitmapData,
              [`img_${index + 1}`]: res.data.BitmapData 

            };
            return updatedValues;
          });
        }, 1000);
      };

//console.log(Boolean(fingerValue));
useEffect(() => {
    
const updateInitialValue =  () => 
    {
        let datas =  fingerValue && fingerValue
        for(let data in datas) {
        setAllImages([...allImages,datas[data].binaryData])
        }

    
//const encodedData = encodedImageString.split(',')[1];

    const newInitialValue = 
      {
        dateApply: new Date().toLocaleDateString(),
        ...Object.fromEntries(
          new Array(5).fill(0).map((_, index) => [`img_${index + 1}`, fingerValue && fingerValue[index] ? fingerValue[index]["img_" + (index + 1)] : null])
        ),
      };

    
      
        aadhaarForm.setFieldsValue(newInitialValue);
  };

  updateInitialValue();
}, [fingerValue, aadhaarForm ]);

const handleFile = (e)=>{
 
}


    return(
       <Layout title="Mobile Update New Entry" Toolbar={<Toolbar />}>
        <div className="flex min-h-screen bg-white">
            <div className="md:w-full p-10">
                <Spin spinning={spin} size="large" >
                <Form  onFinish={onFinish} form={aadhaarForm} encType="multipart/form-data">
                        <div className="grid md:grid-cols-3 gap-3 ">
                            <Item
                                name="fullname"
                                label="Full Name"
                                className="flex flex-col"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please enter your full name' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Fullname*" />
                            </Item>
                            
                            <Item
                                name="fatherName"
                                label="Father Name"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please enter your father's name" }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                            </Item>
                            
                            <Item
                                name="state"
                                label="State"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please select your state' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="State"/>
                            </Item>
                            
                            <Item
                                name="district"
                                label="District"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{ required : true, message: 'Please enter your district' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="District" />
                            </Item>

                            <Item
                                name="aadhaarNumber"
                                label="Aadhaar Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                {required : true, message: 'Please enter your Aadhaar number' },
                                { pattern: /^\d{12}$/, message: 'Aadhaar number must be 12 digits' },
                                ]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Aadhaar Number"/>
                            </Item>

                            <Item
                                name="mobileNumber"
                                label="Mobile Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                { message: 'Please enter your mobile number' },
                                { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits' },
                                ]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Mobile Number" />
                            </Item>

                            <Item
                                name="email"
                                label="Email ( Optional only customer email type )"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                { message: 'Please enter your email' },
                                { type: 'email', message: 'Invalid email address' },
                                ]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Email"/>
                            </Item>
            
                            <Item
                                name="dateApply"
                                label="Date of Apply"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                >
                                <Input  disabled size="large" className="w-28 cursor-not-allowed"  style={{borderRadius:0}}/>
                                
                            </Item>

                    </div>
                    
                    <div className="w-1000 md:flex justify-evenly">

                       {
                        new Array(5).fill(0).map((item , index)=>(

                          <Item  name={`img_${index+1}`} key={index}>
                       
                            <Input  hidden />
                            <div className="flex flex-col justify-center items-center mb-4">
                              <div className="h-[150px] w-[120px] border border-red-500 mb-4 rounded-md">
                              {
                                  fingerValue && fingerValue[index] &&( <img
                                    src={"data:image/bmp;base64," + fingerValue[index].data}
                                    className="cover h-full"
                                  /> ) || (
                                    <img src={finger === index ? "/images/scanning.gif" : "/images/demo-fingerprint.jpg"} className="cover h-full" />
                                  )
                              }
                              </div>
                              <div className="md:w-full">
                                  <Button type="text" className="md:w-full bg-indigo-700  text-white" onClick={()=>onCapture(index)}>Capture</Button>
                              </div>
                            </div>
                      
                          </Item>
                        ))
                       }
                       
                    </div>

                    <Item>
                        <Button type="primary" htmlType="submit" className=" bg-indigo-700  text-white"> Submit</Button>
                    </Item>
                 </Form>
                 </Spin >
            </div>
        </div>
          
       </Layout>
    )
}

export default Demo;