import { useState ,useReducer,useEffect } from "react";
import { Avatar, Button, Layout,Menu} from "antd";
import { DashboardOutlined ,
    DollarOutlined,
    MobileOutlined,
    HistoryOutlined,
    CloudDownloadOutlined,
    LogoutOutlined,
    MenuFoldOutlined,UserOutlined,TableOutlined} from '@ant-design/icons';
import { useNavigate} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const {Sider,Content,Header} = Layout;


const LayoutEl = ({children,title="",Toolbar=null})=>{
    const [open,setOpen] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const [enrollUser , setEnrollUser] = useState()
    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("adminAuth"))
        data&&data&&setEnrollUser(data.data.fullname)
    },[])


    const paddingReducer = (state, action) => {
        if (action == 18) {
          return 5; // Reset padding to 0
        } else {
            
          return 18; // Return the current state for other actions
        }
      };
      
      const [padding, setPadding] = useReducer(paddingReducer, 18);
      
      const mantraDownload = ()=>{
        const a = document.createElement("A")
        a.href = "/driver/MFS100Driver_9.2.0.0.exe"
        a.download = "MFS100Driver_9.2.0.0.exe"
        a.click()
      } 
      
      const mantraRdService = ()=>{
       const a = document.createElement("A")
       a.href = "/driver/MantraRDService_1.0.8.exe"
       a.download = "MantraRDService_1.0.8.exe"
       a.click()
     } 
     
     const mantra100Client = ()=>{
       const a = document.createElement("A")
       a.href = "/driver/MFS100ClientService_9.0.3.8.exe"
       a.download = "MFS100ClientService_9.0.3.8.exe"
       a.click()
     }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const adminlogout = ()=>{
    localStorage.removeItem("adminAuth");
    navigate("/adminlogin")

  }

    const items = [
        {
            label: <p onClick={()=>navigate('/admin/sub')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/sub" ? "bg-blue-500 text-white" : null}`}> <UserOutlined />Sub Admin</p>,
            path: '/admin/sub',
            key: "1"
            
        },
        {
            label: <p onClick={()=>navigate('/admin/user')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/user" ? "bg-blue-500 text-white" : null}`}> <UserOutlined />User</p>,
            path: '/admin/user',
            key: "2"
            
        },
        {
            label: <p onClick={()=>navigate('/admin/entry-token')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/entry-token" ? "bg-blue-500 text-white" : null}`}> <DollarOutlined/> Token</p>,
            path: '/admin/entry-token',
            key: "3"

        },
        {
            label: <p className="flex items-center gap-x-3"> <MobileOutlined /> Data Management </p>,
            path: '/#',
            key: "4",
            children : [
                {
                    label: <p onClick={()=>navigate('/admin/mobiledata')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/mobiledata" ? "bg-blue-500 text-white" : null}`}> <TableOutlined /> Mobile Update Data</p>,
                    path: '/admin/mobiledata',
                    key: "5"
                    
                },
                {
                    label: <p onClick={()=>navigate('/admin/mobiledatahistory')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/mobiledatahistory" ? "bg-blue-500 text-white" : null}`}><HistoryOutlined />Mobile Data Hsitry</p>,
                    path: '/admin/mobiledatahistory',
                    key: "6"
                }
            ]
        },
        {
            label: <p className="flex items-center gap-x-3"> <MobileOutlined /> Demo Management </p>,
            path: '/#',
            key: "7",
            children : [
                {
                    label: <p onClick={()=>navigate('/admin/demographic')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/demographic" ? "bg-blue-500 text-white" : null}`}> <TableOutlined />Demogarphic Update Data</p>,
                    path: '/admin/demographic',
                    key: "8"
                    
                },
                {
                    label: <p onClick={()=>navigate('/admin/demographichistory')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/demographichistory" ? "bg-blue-500 text-white" : null}`}><HistoryOutlined />Demographic Data Hsitry</p>,
                    path: '/admin/demographichistory',
                    key: "9"
                    
                }
            ]
        },
        {
            label: <p className="flex items-center gap-x-3"> <MobileOutlined />Child Management </p>,
            path: '/#',
            key: "10",
            children : [
                {
                    label: <p onClick={()=>navigate('/admin/child')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/child" ? "bg-blue-500 text-white" : null}`}> <TableOutlined />Child Data</p>,
                    path: '/admin/child',
                    key: "11"
                    
                },
                {
                    label: <p onClick={()=>navigate('/admin/child-history')} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/admin/child-history" ? "bg-blue-500 text-white" : null}`}><HistoryOutlined />Child Data Hsitry</p>,
                    path: '/admin/child-hichistory',
                    key: "12"
                    
                }
            ]
        },
        {
            label: <p  className={`px-3 rounded-md flex items-center gap-x-3 text-white" `}><CloudDownloadOutlined /> Download Driver</p>,
            path: '/#',
            key: "13",
            children : [
            
                    {
                        label: <p onClick={()=>mantraDownload()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MFS100Driver_9.2.0.0</p>,
                         key : "14"
                        
                    },
                    {
                        label: <p onClick={()=>mantraRdService()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MantraRDService_1.0.8</p>,
                         key : "15"
                        
                    },
                    {
                        label: <p onClick={()=>mantra100Client()} className={`px-3 rounded-md flex items-center gap-x-3 $`}>MFS100ClientService_9.0.3.8</p>,
                         key : "16"
                        
                    },
            
            ]
            
        },
        {
            label: <p onClick={()=>adminlogout()} className={`px-3 rounded-md flex items-center gap-x-3 ${pathname === "/adminlogin" ? "bg-blue-500 text-white" : null}`}> <LogoutOutlined /> Logout</p>,
            path: '/adminlogin',
            key: "17"
            
        } 
    ]
   
    
    return (
        <Layout>
     
          <Sider trigger={null} className="h-[100vh] overflow-auto overflow-auto force-visible-vertical custom-scrollbar" width="18%" collapsible collapsed={open} style={{position:"fixed"}}>
                <div className="flex flex-col items-center justify-center py-4 gap-4">
                    <h1 className="flex text-xl text-white w-full justify-center items-center">Aadhaar Updateion</h1>
                    <Avatar size={64} icon={<UserOutlined />} className="bg-red-500"/>
                    <h1  className="flex text-semibold text-white w-full justify-center items-center capitalize">{enrollUser&&enrollUser}</h1>
                </div>
                <h1 className="flex text-xl text-white w-full justify-center items-center">Our Services</h1>
              <Menu 
                theme="dark" 
                className="flex flex-col gap-2 py-4"
                mode="inline"
                items={items}
       
                >
                
              </Menu>
            </Sider>
       
            
            <Layout  style={{paddingLeft: `${padding}%`}}>
             <div className="flex flex-col sticky top-0 z-40">
             <Header className="bg-white items-center w-full z-40 px-6 ">
                    <div  className="flex justify-between items-center">
                        <div>
                            <Button onClick={()=>{
                                return(setOpen(!open),setPadding(padding))
                            }} icon={<MenuFoldOutlined />} className="border-0" />
                        </div>
                        <div>
                            <Avatar />
                        </div>
                    </div>
         
                </Header>
                <Header className="flex justify-between bg-rose-400 w-full items-center px-6">
                    <div>
                        <h1 className="text-xl text-white">{title}</h1>
                    </div>
                    <div>
                        {
                           Toolbar && Toolbar
                        }
                    </div>
                </Header>
             </div>
          
                <Content className="p-8">
                    <ToastContainer />
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutEl;