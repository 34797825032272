import { useEffect, useState,useRef } from "react"
import { Form, Input,Button, message ,Upload,Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Layout from "../../shared/layout";
import { useAuth } from '../../context/auth';
import $, { event } from 'jquery'
import { toast } from "react-toastify";
import axios from  "axios";
axios.defaults.baseURL = process.env.REACT_APP_AP

const {Item} = Form

class MFS100Request {
    constructor(BiometricArray) {
    this.Biometrics = BiometricArray;
    }
  }

const ChildEntry = ()=>{
  const [uri] = useState("https://localhost:8003/mfs100/");
  const [aadhaarForm ] = Form.useForm()
  const [finger , setFinger] = useState("");
  const [fingerImage , setFingerImage] = useState("/images/demo-fingerprint.jpg");
  const [fingerValue , setFingerValue] = useState(null);
  const [allImages , setAllImages] = useState([])
  const [imageSource, setImageSource] = useState(null);
  const [auth , setAuth] = useAuth()
  const [spin , setSpin] = useState(false)
  const [urserEmail , setUsewrEmail] = useState(null)
  const [base64 , setBase64] = useState("")
  const [basephoto, setBasephoto] = useState("")
  const [type , setType] = useState("")
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [isShiftOn, setIsShiftOn] = useState(false);


    useEffect(()=>{ 
      const data = JSON.parse(localStorage.getItem("auth"))
      data&&data&&setUsewrEmail(data.data.email)
     },[])

  const onFinish = async (values) => {
    console.log(values);

    const formData = new FormData();
      
        try {
          setSpin(true)
          const {data} = await axios.post('/child/set-finger',values ,{
            headers : {email : urserEmail}
          });
          console.log(data);
  
            if(data.success)
            {
            toast.success(<p className="capitalize">{data.message}</p>)
            aadhaarForm.resetFields()
            setFingerValue(null)
            }
  
          } 
          catch (error) {
            console.log(error);
            toast.error(<p className="capitalize">{error.response.data.message}</p>)
          }
          
          finally{
            setSpin(false)
          }
    
        };

  const currentDate = ()=>{
        const currentDate = new Date().toLocaleString();
          return currentDate;
        }

        //sdk

  const getMFS100Info = () => 
    {
      return getMFS100Client("info");
    }

  const getMFS100KeyInfo = (key) => 
    {
      var MFS100Request = {
        "Key": key,
      };
      const jsondata = JSON.stringify(MFS100Request);
      return postMFS100Client("keyinfo", jsondata);
    }

   const captureFinger = (quality, timeout) => 
   {
      var MFS100Request = {
        "Quality": quality,
        "TimeOut": timeout
      };

      var jsondata = JSON.stringify(MFS100Request);
      return postMFS100Client("capture", jsondata);
    }

  const captureMultiFinger = (quality, timeout, noOfFinger) => 
    {
      var MFS100Request = {
        "Quality": quality,
        "TimeOut": timeout,
        "NoOfFinger": noOfFinger
      };
      const jsondata = JSON.stringify(MFS100Request);
      return postMFS100Client("capturewithdeduplicate", jsondata);
    }

  const verifyFinger = (probFMR, galleryFMR) => 
  {
    var MFS100Request = {
      "ProbTemplate": probFMR,
      "GalleryTemplate": galleryFMR,
      "BioType": "FMR"
    };
    var jsondata = JSON.stringify(MFS100Request);
    return postMFS100Client("verify", jsondata);
  }

  const matchFinger = (quality, timeout, galleryFMR) => {
    var MFS100Request = {
      "Quality": quality,
      "TimeOut": timeout,
      "GalleryTemplate": galleryFMR,
      "BioType": "FMR"
    };
    var jsondata = JSON.stringify(MFS100Request);
    return postMFS100Client("match", jsondata);
  }

  const getPidData = (biometricArray) => {
    var req = new MFS100Request(biometricArray);
    const jsondata = JSON.stringify(req);
    return postMFS100Client("getpiddata", jsondata);
  }

  const getRbdData = (biometricArray) => {
    var req = new MFS100Request(biometricArray);
    const jsondata = JSON.stringify(req);
    return postMFS100Client("getrbddata", jsondata);
  }

  const postMFS100Client = (method, jsonData) => {
    var res;
    $.support.cors = true;
    var httpStaus = false;
    $.ajax({
        type: "POST",
        async: false,
        crossDomain: true,
        url: uri + method,
        contentType: "application/json; charset=utf-8",
        data: jsonData,
        dataType: "json",
        processData: false,
        success: function (data) {
            httpStaus = true;
            res = { httpStaus: httpStaus, data: data };
        },
        error: function (jqXHR, ajaxOptions, thrownError) {
            res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
        },
    });
    return res;
  }

  const getMFS100Client = (method) => {
    let res;
    const httpStatus = false;

    fetch(uri + method)
    .then(response => response.json())
    .then(data => {
      res = { httpStatus: true, data: data };
    })
    .catch(error => {
      res = { httpStatus: false, err: getHttpError(error) };
    });

    return res;
  }

  const getHttpError = (error) => {
    let err = "Unhandled Exception";
    if (error.message === 'Failed to fetch') {
      err = 'Network request failed';
    }
    // ... (Other error handling logic)
    return err;
  }
      //end sdk



    const Toolbar = ()=>{
        return (
            <Link to="/retailer/child-history" >
                <Button
                    size="large"
                    shape="round"
                    type="text"
                    style={{borderRadius:0,}} className="bg-[#4096FF] text-white textHoverBg:text-white mb-2" >
                    Child Enrollment History
                </Button>
            </Link>
        )
    }

    const  onChange = (date, dateString) => 
      {
        aadhaarForm.setFieldsValue({ dateApply: dateString });
      }

    const onCapture = (index) => 
    {
        setFinger(index);
        setTimeout(async function () {
          var quality = 60;
          var timeout = 10;
          var res =  captureFinger(quality, timeout);
          if(!res) return false
          setFinger(false);
      
          setFingerImage((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[index] = {
              ...updatedImages[index],
              data: "data:image/bmp;base64," + res.data.BitmapData, 
            };
            return updatedImages;
          });

          setFingerValue((prevValues) => {
            const updatedValues = { ...prevValues };
            updatedValues[index] = {
              ...updatedValues[index],
              data: res.data.BitmapData,
              [`img_${index + 1}`]: res.data.BitmapData 

            };
            return updatedValues;
          });
        }, 1000);
      };



useEffect(() => {
    
const updateInitialValue =  () => 
    {
        let datas =  fingerValue && fingerValue
        for(let data in datas) {
        setAllImages([...allImages,datas[data].binaryData])
        }

    
//const encodedData = encodedImageString.split(',')[1];

    const newInitialValue = 
      {
        dateApply: new Date().toLocaleDateString(),
        ...Object.fromEntries(
          new Array(5).fill(0).map((_, index) => [`img_${index + 1}`, fingerValue && fingerValue[index] ? fingerValue[index]["img_" + (index + 1)] : null])
        ),
      };

     
      
        aadhaarForm.setFieldsValue(newInitialValue);
  };

  updateInitialValue();
}, [fingerValue, aadhaarForm ]);


const onPoiChange = (e)=>{
  const file = e.files[0]
  if(file.size > 5242800)
  return(
  toast.error("File size should be less than and equal 5Mb")
 )
 if(file.type !== "application/pdf")
 return(
  toast.error("File should only pdf format")
 )
 const reader = new FileReader();
  reader.onload = (e) => {
      var base64Data = e.target.result.split(',')[1];
      setBase64(base64)
      const poiValue = {
        poivalues : base64Data
      }
      aadhaarForm.setFieldsValue(poiValue)
  };
  reader.readAsDataURL(file);

 }



const onPhotoChange = (e) => {
  const file = e.files[0];
  if (file.size > 5242800)
    return toast.error("File size should be less than or equal to 5Mb");
  if (file.type !== "image/jpeg" && file.type !== "image/png")
    return toast.error("File should be in JPEG or PNG format");
  
  const reader = new FileReader();
  reader.onload = (e) => {
    const basephoto = e.target.result.split(',')[1];
    setBasephoto(basephoto);
    const photoValues = {
      photoValue: basephoto
    };
    aadhaarForm.setFieldsValue(photoValues);
  };
  reader.readAsDataURL(file);
};

const onType =(value)=>{
  setType(value)

}

const handleKeyPress = (e) => {
  setIsCapsLockOn(e.getModifierState('CapsLock'));
  setIsShiftOn(e.getModifierState('Shift'));

  if (isCapsLockOn || isShiftOn) {
      e.preventDefault();
  }
};

    return(
       <Layout title="Child New Enrollment" Toolbar={<Toolbar />}>
        <div className="flex min-h-screen bg-white">
            <div className="md:w-full p-10">
                
                <Form  onFinish={onFinish} form={aadhaarForm} encType="multipart/form-data">
                        <div className="grid md:grid-cols-4 gap-3 ">
                            <Item
                                name="fullname"
                                label="Child Full Name"
                                className="flex flex-col"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: 'Please enter your full name' },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter a valid text (letters and spaces only)"
                                  }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Full Name*" onKeyPress={handleKeyPress}/>
                                  {isCapsLockOn && <span style={{ color: 'red' }}>Caps Lock is on! only accept lowercase</span>}
                                  {isShiftOn && <span style={{ color: 'red' }}>Shift key is on!</span>}
                            </Item>
                           
                              
                            <Item
                                name="fatherName"
                                label="C/o Name"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: "Please enter your father's name" },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter a valid name (letters and spaces only)"
                                  }
                              ]}
                              >
                            <Input
                               size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                            </Item>

                            <Item
                                name="gender"
                                label="Gender"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {   required : true,message: "Please enter your gender" },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter a gender (letters  only)"
                                  }
                                ]}
                            >
                                <Input size="large" style={{borderRadius:0}} placeholder="Gender"/>
                            </Item>
                                    
                              
                            <Item
                                name="dob"
                                label="DOB"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: "Please enter your dob" },
                                  {
                                    pattern: /^[0-9-]+$/,
                                    message: "Only numbers and '-' are allowed",
                                  }
                              ]}
                            >
                                <Input size="large" style={{borderRadius:0}} maxLength={10} placeholder="01-01-2020"/>
                            </Item>
                            
                            <Item
                                name="houseno"
                                label="House No/Bldg/Apt"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{ message: "Please enter your house no" }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Enter your house no"/>
                            </Item>
                          
                            <Item
                                name="landmark"
                                label="Landmark"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please enter your landmark"}]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Land Mark"/>
                            </Item>

                            <Item
                                name="area"
                                label="Area/Localty"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please enter your area/localty" }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Area/Localty"/>
                            </Item>

                            <Item
                                name="village"
                                label="Village"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  
                                    message: "Please enter your village",
                                    required : true, message: 'Please enter your village'
                                }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Village"/>
                            </Item>
                            
                            <Item
                                name="postoffice"
                                label="Post Office"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  
                                    message: "Please enter your post office",
                                    required : true, message: 'Please enter your post office'
                                  }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Post Office"/>
                            </Item>

                            <Item
                                name="pin"
                                label="Pin Code"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {required : true, message: 'Please enter your pin code'},
                                  { pattern: /^\d{6}$/, message: 'Pin number must be 6 digits' }
                                  ]}
                                >
                            <Input size="large" style={{borderRadius:0}} placeholder="Pin Code"/>
                            </Item> 
                    
                            <Item
                                name="district"
                                label="District"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                               rules={[
                                { required : true, message: 'Please enter your district' },
                                {
                                  pattern: /^[A-Za-z\s]+$/,
                                  message: "Please enter text only"
                                }
                              ]}
                            >
                                <Input size="large" style={{borderRadius:0}} placeholder="District" />
                            </Item>

                            <Item
                                name="state"
                                label="State"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: 'Please select your state' },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter text only"
                                  }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="State"/>
                            </Item>

                            <Item
                                name="mobileNumber"
                                label="Mobile Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: 'Please enter your mobile number' },
                                  { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits' }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Mobile Number" />
                            </Item>

                            <Item
                                name="email"
                                label="Email (Optional)"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  { message: 'Please enter your email' },
                                  { type: 'email', message: 'Invalid email address' }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Email"/>
                            </Item>
                            <Item name="hoftype"
                                label="Select Hof Type"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please select hof type' }]}
                              
                            >
                              <Select onChange={onType} >
                                <Select.Option value="hfather">Father</Select.Option>
                                <Select.Option value="hmother">Mother</Select.Option>
                                <Select.Option value="hguardian">guardian</Select.Option>
                              </Select>
                            </Item>
                            {
                              (() => {
                              if (type === "hfather") {
                                  return(
                                    <Item
                                        name="hfather"
                                        label="Father"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  
                                          message: "Please enter father",
                                          required : true, message: 'Please enter father name'
                                         }]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                                    </Item>
                                  )
                              } 
                              else if (type === "hmother")
                                    return(
                                      <Item
                                          name="hmother"
                                          label="mother"
                                          wrapperCol={{span: 24}} labelCol={{span:24}}
                                          rules={[{  
                                            message: "Please enter mother",
                                            required : true, message: 'Please enter mother'
                                           }]}
                                      >
                                          <Input size="large" style={{borderRadius:0}} placeholder="Mother"/>
                                      </Item>
                                    )
                              else if (type === "hguardian")
                                  return(
                                    <Item
                                        name="hguardian"
                                        label="Guardian"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  
                                          message: "Please enter guardian name",
                                          required : true, message: 'Please enter guardian name'
                                         }
                                        ]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Guardian name"/>
                                    </Item>
                                  )
                              
                              })()
                          }
                           
                            
                            <Item
                                name="aadhaarNumber"
                                label="Father/Mother/Guardian Aadhaar Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                {required : true, message: 'Please enter your Aadhaar number' },
                                { pattern: /^\d{12}$/, message: 'Aadhaar number must be 12 digits' }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Aadhaar Number"/>
                            </Item>

                            <div className="flex md:flex-row flex-col">

                            <Item
                                label="Photo"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                onChange={(e)=>onPhotoChange(e.target)}
                                className="md:mr-6" 
                                rules={[{ required:true, message: "Please upload photo" }]}
                              >
                                <Upload multiple={false}>
                                    <Button size="large" className="w-fit" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Item>
                            
                            <Item
                                name="photoValue"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{ required : true, message: "Please upload photo" }]}
                                >
                                <Input hidden placeholder="photo"  />
                            </Item>  
                             <Item
                                label="Document"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                onChange={(e)=>onPoiChange(e.target)}
                                rules={[{ required : true, message: "Please upload Document" }]}
                                >
                                <Upload multiple={false}>
                                    <Button size="large" className="w-fit" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Item>
                            
                            <Item
                                name="poivalues"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{required:true,  message: "Please upload Document" }]}
                                >
                                <Input hidden placeholder="Document"  />
                            </Item>  
                          
                            </div>
            
                            <Item
                                name="dateApply"
                                label="Date of Apply"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                >
                                <Input  disabled size="large" className="w-28 cursor-not-allowed"  style={{borderRadius:0}}/>
                                
                            </Item>

                    </div>
                    
                    <div className="w-1000 md:flex justify-evenly">

                       {
                        new Array(5).fill(0).map((item , index)=>(

                          <Item  name={`img_${index+1}`} key={index}>
                       
                            <Input  hidden />
                            <div className="flex flex-col justify-center items-center mb-4">
                              <div className="h-[150px] w-[120px] border border-red-500 mb-4 rounded-md">
                              {
                                  fingerValue && fingerValue[index] &&( <img
                                    src={"data:image/bmp;base64," + fingerValue[index].data}
                                    className="cover h-full"
                                  /> ) || (
                                    <img src={finger === index ? "/images/scanning.gif" : "/images/demo-fingerprint.jpg"} className="cover h-full" />
                                  )
                              }
                              </div>
                              <div className="md:w-full">
                                  <Button type="text" className="md:w-full bg-indigo-700  text-white" onClick={()=>onCapture(index)}>Capture</Button>
                              </div>
                            </div>
                      
                          </Item>
                        ))
                       }
                       
                    </div>

                    <Item>
                        <Button type="primary" htmlType="submit" className=" bg-indigo-700  text-white"> Submit</Button>
                    </Item>
                 </Form>
            </div>
        </div>
          
       </Layout>
    )
}

export default ChildEntry;