import MobileData from "../mobile/mobiledata"
import MobileDataHistory from "../mobile/mobiledatahistory"
import Layout from "./layout"

const SubAdmin = ()=>{
    return (
        <Layout>
            <a>Welcome to Sub Admin</a>
        </Layout>
        
    )
}


export default SubAdmin;