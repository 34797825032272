import { useState,useEffect,useContext,createContext } from "react";
import axios from "axios";
const AuthContext = createContext();

export const AuthProvider = ({children})=>{
   const [auth , setAuth] = useState({
    auth : false,
    token : ""
   })

   axios.defaults.baseURL = process.env.REACT_APP_API
  
   useEffect(()=>{
    const data = JSON.parse(localStorage.getItem("auth"))
    data && data && setAuth({
        ...auth,
        auth : data.success,
        token : data.token,
        data : data.data
    })


    

    // eslint-disable-next-link

   },[])
    return (
        <AuthContext.Provider value={[auth ,setAuth]}>
            {children}
        </AuthContext.Provider >
    )
}

export const useAuth = ()=>useContext(AuthContext)