import { useState,useEffect } from "react";
import { Skeleton,Switch,Button,Modal,Spin,Input,Form,Select} from 'antd';
import Layout from '../layout';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSWR,{mutate} from "swr";
import axios from "axios";
import { useNavigate } from "react-router-dom";
axios.defaults.baseURL = process.env.REACT_APP_API

const {Item} = Form;
const fetcher = async (url)=>{
   try {
     const {data} = await axios.get(url);
     return data;
   } catch (error) {
    throw new Error(error)
   }
}

const Sub = () => {
    const [Editform] = Form.useForm()
    const [rmodel,setRmodel] = useState(false)
    const [value , setValue] = useState();
    const [status,setStatus] = useState("")
    const [subtype,setSubtype] = useState("")
    const [edit , setEdit] = useState({});

    const [model,setModel] = useState(false)
    const [spin ,setSpin] = useState(false);
    const {data , error , isloading} = useSWR ("/subadmin" , fetcher)


    const onStatusChange = async (id , value)=>{
        try {
             await axios.put(`/subadmin/${id}`, {status : value ? "1" : "0"})
             mutate("/subadmin")
            
        } catch (error) {
            toast.success("Something went wrong")
        }
    }
    const [loader,setLoader] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [registerForm] = Form.useForm()
    const [roleForm] = Form.useForm()
    const navigate = useNavigate()

    const onFinish =async (values)=>{
        try {
            setSpin(true)
       const {data} =  await axios.post("/subAdmin/signup" ,values);
         if(data.success){
          toast.success(<p className="capitalize">{data.message}</p>)
          registerForm.resetFields()
          setModel(false)

         }
        } catch (error) {
            toast.error(<p className="capitalize">{error.response.data.message} </p>);
        }
        finally {
            setSpin(false)
        }
    }
    useEffect(()=>{
        let releaseTimer = setTimeout(()=>setLoader(false),2000)
        return ()=>{
         clearTimeout(releaseTimer)
        }
     },[])
     if(loader) return (
         <div className="min-h-screen flex items-center justify-center">
             <Spin size="large" />
         </div>
     )

     const updateData = (values)=>{
        setEdit({id:values.id })
        const subtype = values.action
        setValue(values)
        setRmodel(true)
     Editform.setFieldsValue(values)
     
    }

    const onRoleFinish = async (value)=>{
        try {
            await axios.put(`/subAdmin/role/${edit.id}`,value)
            toast.success("Update Success")
            setRmodel(false)
            mutate("/subadmin")

        } catch (error) {
            toast.success("Something went wrong")
           
        }
    }
  return (
    <Layout>
        <div>
            <div className='flex justify-end'>
                <Button onClick={()=>setModel(true)} type="primary" size='large'  className=" bg-indigo-700 float-right  text-white font-semibold text-xl">Create Sub Admin</Button>
          </div>
        </div>
       {

        isloading && <Skeleton active /> || <table className="w-full border">
        <caption className='mb-4 font-semibold text-xl'>Sub Admin List</caption>
            <thead>
                <tr>
                    <th className="border py-3">S.no</th>
                    <th className="border py-3">Fullname</th>
                    <th className="border py-3">Mobile no</th>
                    <th className="border py-3">Email Id</th>
                    <th className="border py-3">Status</th>
                    <th className="border py-3">Created on</th>
                    <th className="border py-3">Live Role</th>
                    <th className="border py-3">Role Update</th>
                    <th className="border py-3">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    data && data.result.map((user , index)=>(
                    <tr className=" odd:bg-[#c8d7f992]  even:bg-pink-100 text-center" key={index}>
                        <td className='py-2'>{(index)+1}</td>
                        <td className="capitalize">{user.fullname}</td>
                        <td className=''>{user.mobile}</td>
                        <td className="">{user.email}</td>
                        <td className='capitalize text-start'>{user.status ? "active" : "deactive"}</td>
                        <td>{ new Date(user.createdate).toLocaleDateString() } {new Date(user.createdate).toLocaleTimeString()}</td>
                        <td className="">{user.subtype}</td>
                        <td className="lowercase">
                            <button  onClick={()=>updateData(user)}><i class='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'  ></i> </button>               
                        </td>
                        <td><Switch defaultChecked={user.status}  onChange={(value)=>onStatusChange(user.id , value)} /></td>
                    </tr>

                    ))
                }   
            </tbody>
        </table>
        }
        <Modal
              centered
              open={model}
              onCancel={() => setModel(false)}
              width={500}
              footer ={null}
              style={{ maxHeight: 700, overflowY: 'auto' }}
              
            >
                
                <div className="bg-white  rounded-lg border-0 w-12/12 p-8  gap-y-4   animate__animated animate__zoomIn animate_faster">
                    <div className="flex justify-center items-center ">
                        <h className="text-2xl font-semibold">Sub Admin Registration</h>
                    </div>
                <Spin spinning={spin}>
                        <Form onFinish={onFinish} form={registerForm}>
                            <Item 
                                name="fullname"
                                rules={[
                                    {required:true,message: 'Fullname  is required'}
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Fullname*" />
                            </Item>
                            <Item 
                                name="mobile"
                                rules={[
                                    {required:true,message: 'Mobile number is required'}
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Mobile number*" />
                            </Item>
                            <Item 
                                name="email"
                                rules={[
                                    {required:true,message: 'Email is required'}
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Email*" />
                            </Item>
                            <Item 
                                name="password"
                                rules={[
                                    {required:true,message: 'Password is required'}
                                ]}
                                >
                                <Input.Password
                                    size="large"
                                    style={{borderRadius:0}}
                                    placeholder="*********"
                                    visibilityToggle={{
                                        visible: passwordVisible,
                                        onVisibleChange: setPasswordVisible,
                                        }}
                                />
                            </Item>
                            <Item>
                                <Button
                                    htmlType="submit" 
                                    size="large"
                                    style={{borderRadius:0}} className="w-full bg-indigo-900 text-white" >Register Now</Button>
                            </Item>
                        
                        </Form>
                    </Spin>
                </div>
        </Modal>

        {/* Role update model */}
        <Modal
              centered
              open={rmodel}
              onCancel={() => setRmodel(false)}
              width={400}
              footer ={null}
              style={{ maxHeight: 300, overflowY: 'auto' }}
              
            >
                
                <div className="bg-white  rounded-lg border-0 w-12/12 p-8  gap-y-4   animate__animated animate__zoomIn animate_faster">
                    <div className="flex justify-center items-center ">
                        <h className="text-2xl font-semibold">Sub Admin Role Update</h>
                    </div>
                <Spin spinning={spin}>
                        <Form onFinish={onRoleFinish} form={roleForm} >
                        <Item
                            name="action"
                            label="Role"
                            className="flex flex-col"
                            wrapperCol={{span: 24}} labelCol={{span:24}}
                            size="large"
                        >
                            <Select className="w-full"  value={subtype} >
                                <Select.Option value="n">N</Select.Option>
                                <Select.Option value="mobile">Mobile</Select.Option>
                                <Select.Option value="demographic">Demographic</Select.Option>
                                <Select.Option value="child">Child</Select.Option>
                            </Select>
                        </Item>
                            <Item>
                                <Button
                                    htmlType="submit" 
                                    size="large"
                                    style={{borderRadius:0}} className="w-full bg-indigo-900 text-white" >Submit</Button>
                            </Item>
                        
                        </Form>
                    </Spin>
                </div>
        </Modal>
    </Layout>
  );
};

export default Sub;