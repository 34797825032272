import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button,Modal,Spin,Skeleton} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Layout from "../shared/layout"
import { useAuth } from '../context/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import useSWR , {mutate} from 'swr';
axios.defaults.baseURL = process.env.REACT_APP_API

const { Option } = Select;
const {Item} = Form

const fetcher = async (url)=>{
  try {
    const {data} = await axios.get(url)
    return data.result
  } catch (error) {
    throw new Error(error)
  }
}
const LoadMoney = () => {
  const [loading,setLoading] = useState(false)
  const [status,setStatus] = useState('mobile')
  const [spin ,setSpin] = useState(false);
  const [value , setValue] = useState();
  const [model,setModel] = useState(false)
  const [auth] = useAuth()
  const [datas , setDatas] = useState()
  const [form] = Form.useForm()
  const { data, isLoading } = useSWR(`/user-wallet?email=${datas&&datas.data&&datas.data.email }`, fetcher, {
    refreshInterval: 2000
  });
  
  const [newInitialValue , setInitialValue] = useState(null)
  
  useEffect(()=>{
     const data = JSON.parse(localStorage.getItem("auth"))
     setDatas(data)

    const newInitialValue = {
      email : data&&data.data.email,
      fullname : data&&data.data.fullname
    }

    setInitialValue(newInitialValue)
  },[])


  const onFinish = async (value)=>{
    try {
      setSpin(true)
       const {data} = await axios.post("/user-wallet" ,value)
       if(data.success)
       {
        toast.success("Balance will reflect after few minutes ")
        form.resetFields()
        setModel(false)
        mutate("/user-wallet")
       }
    } catch (error) {
      toast.error("Something is not right")
    }
    finally{
      setSpin(false)

    }

  }


  return (
    <Layout>
        <div className="bg-gray-100 min-h-screen ">
          <div className='flex justify-end'>
            <Button onClick={()=>setModel(true)} type="primary"  className=" bg-indigo-700 float-right  text-white">Add Mony</Button>
          </div>

          <Spin spinning="">
                    {
                        //isLoading && <Skeleton active /> ||
                        <table className="w-full border">
                            <caption className='font-semibold text-2xl' >Transation History</caption>
                        <thead>
                            <tr>
                                <th className="border py-3">S.no</th>
                                <th className="border py-3">Fullname</th>
                                <th className="border py-3">Email Id</th>
                                <th className="border py-3">Amount</th>
                                <th className="border py-3">Status</th>
                                <th className="border py-3">Created on</th>
                            </tr>
                        </thead>

                        <tbody>
                          {
                            data && data.map((user , index)=>(
                              <tr className=" odd:bg-[#c8d7f992]  even:bg-pink-100 text-center" key={index}>
                            <td className="py-3">{index+1}</td>
                            <td className="capitalize">{user.fullname}</td>
                            <td className="lowercase">{user.email}</td>
                            <td className="capitalize">{user.amount}</td>
                            <td className="capitalize">{user.action}</td>
                            <td>{ new Date(user.dateofapply).toLocaleDateString() } {new Date(user.dateofapply).toLocaleTimeString()}</td>
                          </tr>
                            ))
                          }
                          
                        </tbody>
                            
                        </table>
                    }
             
                </Spin>

            <Modal
              title="Add Balance"
              centered
              open={model}
              onCancel={() => setModel(false)}
              width={300}
              footer ={null}
              style={{ maxHeight: 700, overflowY: 'auto' }}
              
            >

  
                <Spin spinning = {spin}>
                <Form  encType="multipart/form-data" className='relative' onFinish={onFinish} initialValues={newInitialValue && newInitialValue} form={form} >
                        <div className="grid md:grid-cols-1 gap-3">
                        <Item 
                              name="fullname"
                              className="absolute top-0 w-[100%] "

                              >
                            <Input hidden  />
                          </Item>
                          <Item 
                              name="email"
                              className="absolute top-0 w-[100%] "

                              >
                            <Input hidden />
                          </Item>
                           
                          <Item 
                              name="amount"
                              label="Amount"
                              //className='flex  md:flex-row flex-col  w-full'
                              wrapperCol={{span: 24}} labelCol={{span:24}}
                              rules={[
                                {  required : true, message: 'Please type amount' }
                               
                                ]}>
                              <Input type="number" />
                          </Item>
                            
                          <Item
                              name="trannumber"
                              label="Transaction Number"

                              //className='flex  md:flex-row flex-col  w-full'
                              wrapperCol={{span: 24}} labelCol={{span:24}}
                              rules={[{  required : true, message: 'Please enter your transaction' }]}>
                              <Input  style={{borderRadius:0}} placeholder="Lest 5 digit of Transaction*" />
                          </Item>
                    </div>
                    <Item>
                        <Button type="primary" htmlType="submit" className=" bg-indigo-700 float-right  text-white"> Submit</Button>
                    </Item>
                 </Form>
                </Spin> 
            
            </Modal>
        </div>
    </Layout>
  )
};

export default LoadMoney;
