import { useState } from "react";
import { Select,Input} from "antd";
import Layout from "../../layout";
import axios from "axios";
import useSWR,{mutate} from "swr";
import moment from 'moment';
axios.defaults.baseURL =  process.env.REACT_APP_API

const {Search} = Input

const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}

const AdminChildHistory = ()=>{
    const [status,setStatus] = useState("")
    const [filteredData, setFilteredData] = useState(null);
    const { data:childData, error, isLoading } = useSWR(`/child/set-finger/admin/child-history?action=${status&&status}`, fetcher)

    const onStatusFilter =(status)=>{
        setStatus(status)
        mutate(`/childc/set-finger/admin/child-history?action=${status}`)
    }
    const onSearch = (value) => {
        if (!value.trim()) {
            setFilteredData(null);
            return;
        }
        const filteredData = childData.filter(data => String(data.aadhaarnumber).includes(value));
        setFilteredData(filteredData);
       
    }
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(e.target.value);
        }
    };
    const onSearchDate = (value) => {
        if (!value.trim()) {
            setFilteredData(null);
            return;
        }
        const filteredData = childData.filter(data => {
            const searchDate = moment(value, 'MM-DD-YYYY').toDate();
            const dataDate = moment(data.dateofupdate).toDate();
            return moment(dataDate).isSame(searchDate, 'day');
        });
        setFilteredData(filteredData);
    };
    const handleKeyPressDate = (e) => {
        if (e.key === "Enter") {
            onSearchDate(e.target.value);
        }
    };

    return (
        <Layout>
            <div className="flex md:float-right gap-2 w-[150] mb-2 md:mb-0 md:ml-2 ml-0">
                <Search placeholder="Search " onSearch={onSearch} onPressEnter={handleKeyPress}  enterButton />
            </div>
            <div className="flex md:float-right gap-2 w-[150]">
                <Search placeholder="03-20-2024" onSearch={onSearchDate} onPressEnter={handleKeyPressDate}  enterButton />
            </div>
            <div className="flex w-full mt-2">
             <caption className="items-center justify-center  md:text-2xl  text-lg mb-4  flex w-full">Child Data Working Complete History</caption>
            </div>
            <table className="w-full">
            
            <thead>
                <tr>
                    <th className="border py-3">S.no</th>
                    <th className="border py-3">Fullname</th>
                    <th className="border py-3">Fathername</th>
                    <th className="border py-3">State</th>
                    <th className="border py-3">District</th>
                    <th className="border py-3">Aadhaarnumber</th>
                    <th className="border py-3">Mobilenumber</th>
                    <th className="border py-3">Email</th>
                    <th className="border py-3">
                        Status
                        <Select className="w-full" value={status} onChange={onStatusFilter}>
                        <Select.Option value=""> All data</Select.Option>
                        <Select.Option value="pending"> Pending</Select.Option>
                        <Select.Option value="success"> Success</Select.Option>
                        <Select.Option value="rejected">Rejected</Select.Option>
                        </Select>
                    </th>
                    <th className="border py-3">Created on</th>
                    <th className="border py-">Update on</th>
                    
                </tr>
            </thead>
            <tbody>
                 {
                    (filteredData || childData) && (filteredData || childData).map((data,index)=>(
                        <tr key={index} className="text-center">
                        <td className='py-2'>{(index)+1}</td>
                        <td className="capitalize cursor-pointer ">{data.fullname}</td>
                        <td className="capitalize max-md:hidden ">{data.fathername}</td>
                        <td className='max-md:hidden'>{data.state}</td>
                        <td className='max-md:hidden'> {data.district}</td>
                        <td className='max-md:hidden'> {data.aadhaarnumber}</td>
                        <td className='max-md:hidden'> {data.mobile}</td>
                        <td className='max-md:hidden lowercase'> {data.email}</td>
                        <td className='max-md:hidden'> {data.action}</td>
                        <td className="max-md:hidden">{ new Date(data.dateofapply).toLocaleDateString() } {new Date(data.dateofapply).toLocaleTimeString()}</td>
                        <td className="max-md:hidden">{ new Date(data.dateofupdate).toLocaleDateString() } {new Date(data.dateofupdate).toLocaleTimeString()}</td>
                            
                        
                     </tr>
                    ))
                 }
            </tbody>
        </table>
        </Layout>
    )
}

export default AdminChildHistory;