import { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../shared/layout";
import axios from "axios";
import {Spin , Skeleton,Modal,Form, Select,Upload,Input,Button} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { toast } from "react-toastify"
import { useChildContext } from "../../context/child";
axios.defaults.baseURL = process.env.REACT_APP_API
const {Item} = Form

const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}

const ChildcHistry = ()=>{
    const [status , setStatus , childData , mutate,isLoading] = useChildContext()
    const [spin ,setSpin] = useState(false);
    const [value , setValue] = useState({});
    const [model,setModel] = useState(false)
    const [enrollUser , setEnrollUser] = useState()
    const [type , setType] = useState("")
    const [base64 , setBase64] = useState("")
    const [basephoto, setBasephoto] = useState("")
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [isShiftOn, setIsShiftOn] = useState(false);

    const [Editform] = Form.useForm()

    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("auth"))
        data&&data&&setEnrollUser(data.data.fullname)
       },[])

       const onFinish = async (values)=>{
        try {
          setSpin(true) 
          const res = await axios.put(`child/set-finger/${value.id}`,values)
          toast.success("Data Successfully Updated")
          setModel(false)
          mutate(`/child/set-finger?action=${status}`)
        } catch (error) {
           toast.error("Something went wrong")
          
        }
        finally {
          setSpin(false)
        }
       }


    const Toolbar = ()=>{
        return (
            <Link to="/retailer/childentry">
                <Button
                    size="large"
                    shape="round"
                    style={{borderRadius:0}} className="bg-indigo-900 text-white  mb-2">Child New Enroll</Button>
            </Link>
        )
    }
   
    const updateData = (values)=>{
        const applyTime = new Date(values.dateofapply)
        const currentTime = new Date()
        const timeDifference = (currentTime - applyTime)/60000
        if(timeDifference >= 30)
        return  toast.info("! According to Apply time with in 30 Minutes Editable")
        setValue(values)
        setModel(true)
     Editform.setFieldsValue(values)
    }
   
    


    const onStatusFilter =(status)=>{
        setStatus(status)
        mutate(`/child/set-finger?action=${status}`)
    }

    const onType =(value)=>{
        setType(value)
      }
    
    const downloadPhoto = async (aadhar, fullname) => {
        try {
            const response = await axios.get(`/child/download/photo/retiler?aadhar=${aadhar}`);
            const data = response.data;
            const doc =  `data:image/jpeg;base64,${data.document}`
            const a = document.createElement("A");
            a.href = doc;
            a.download = `${fullname}_silip.jpg`;
            a.click();
            toast.success("Download Successfuly")
        } catch (error) {
            toast.error("Something went wrong")
            console.error("Error occurred while downloading data:", error);
        }
        
    };

    const onPoiChange = (e)=>{
        const file = e.files[0]
        if(file.size > 250000)
        return(
        toast.error("File size should be less than and equal 250kb")
       )
       if(file.type !== "application/pdf")
       return(
        toast.error("File should only pdf format")
       )
       const reader = new FileReader();
        reader.onload = (e) => {
            var base64Data = e.target.result.split(',')[1];
            setBase64(base64)
            const poiValue = {
              poivalues : base64Data
            }
            Editform.setFieldsValue(poiValue)
        };
        reader.readAsDataURL(file);
      
       }

    const onPhotoChange = (e) => {
        const file = e.files[0];
        if (file.size > 250000)
          return toast.error("File size should be less than or equal to 250kb");
        if (file.type !== "image/jpeg" && file.type !== "image/png")
          return toast.error("File should be in JPEG or PNG format");
        
        const reader = new FileReader();
        reader.onload = (e) => {
          const basephoto = e.target.result.split(',')[1];
          setBasephoto(basephoto);
          const photoValues = {
            photoValue: basephoto
          };
          Editform.setFieldsValue(photoValues);
        };
        reader.readAsDataURL(file);
      };
    
    const handleKeyPress = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
        setIsShiftOn(e.getModifierState('Shift'));
      
        if (isCapsLockOn || isShiftOn) {
            e.preventDefault();
        }
      };
    
    return(
        <Layout title="Child Enrollment Histry" mess="One Hof" Toolbar={<Toolbar />}>
            <div className=" w-full shadow-md rounded-md p-5 border flex flex-col gap-y-5">
                <h1 className="font-semibold text-[18px] capitalize">{enrollUser&&enrollUser}</h1>
                <Spin spinning={isLoading}>
                    {
                        isLoading && <Skeleton active /> ||
                        <table className="w-full border">
                            <caption className="font-semiBolad text-2xl mb-4">Child Enrollment History</caption>
                        <thead>
                            <tr>
                                <th className="border py-3">S.no</th>
                                <th className="border py-3">Fullname</th>
                                <th className="border py-3">Aadhar no</th>
                                <th className="border py-3">Mobile no</th>
                                <th className="border py-3">Email Id</th>
                                <th className="border py-3">Created on</th>
                                <th className="border py-3">
                                    Status
                                    <Select className="w-full" value={status} onChange={onStatusFilter}>
                                    <Select.Option value=""> All data</Select.Option>
                                    <Select.Option value="pending"> Pending</Select.Option>
                                    <Select.Option value="success"> Success</Select.Option>
                                    <Select.Option value="rejected">Rejected</Select.Option>
                                    </Select>
                                
                                </th>
                                <th className="border py-3">Download Slip</th>
                                <th className="border py-3">Action</th>
				<th className="border py-3">Reason</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    childData && childData.map((item , index)=>(
                                        <tr className=" odd:bg-[#c8d7f992]  even:bg-pink-100 text-center" key={index}>
                                            <td className="py-3">{index+1}</td>
                                            <td className="capitalize"> {item.fullname}</td>
                                            <td> {item.aadhaarnumber}</td>
                                            <td> {item.mobile}</td>
                                            <td className="lowercase"> {item.email}</td>
                                            <td>{ new Date(item.dateofapply).toLocaleDateString() } {new Date(item.dateofapply).toLocaleTimeString()}</td>
                                            <td className="capitalize">
                                                <div className="flex items-center justify-center gap-3">
                                            <i class={`bx bxs-circle  mt-2 ${item.action === "pending" ? "text-red-700 bx-flashing" : "text-green-700"}`}></i>
                                                <p>
                                                    {item.action}
                                                
                                                </p>
                                            </div>
                                            </td>
                                            <td>
                                                {
                                                    (() => {
                                                    if (item.action === "pending") {
                                                        return (
                                                            <i class='bx bx-error-alt text-2xl'></i>
                                                        );
                                                    } 
                                                    else if (item.action === "success")
                                                         return(
                                                            <button onClick={()=>downloadPhoto(item.aadhaarnumber,item.fullname)}> <i class='bx bx-down-arrow-circle bx-tada-hover  text-green-600 text-2xl'></i> </button>
                                                         )
                                                    else if (item.action === "rejected")
                                                        return(
                                                        <i class='bx bx-x text-red-600 text-2xl'></i>
                                                        )
                                                    
                                                    })()
                                                }
                                            </td>
                                            <td className="lowercase">
                                                {
                                                    (() => {
                                                    if (item.action === "pending") {
                                                        return (
                                                            <button onClick={()=>updateData(item)}><i class='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'></i> </button>
                                                        );
                                                    } 
                                                    else if (item.action === "success")
                                                         return(
                                                            <i class='bx bx-check text-green-600 text-2xl'></i>
                                                         )
                                                    else if (item.action === "rejected")
                                                        return(
                                                        <i class='bx bx-x text-red-600 text-2xl'></i>
                                                        )
                                                    
                                                    })()
                                                }
                                            </td>
					<td>{item.rejectedmsg}</td>
                                        </tr>
                                    ))
                                }
                        </tbody>
                        </table>
                    }
             
                </Spin>
                <Modal
                    title="Child Enrollment Correction"
                    centered
                    open={model}
                    onCancel={() => setModel(false)}
                    width={600}
                    footer ={null}
                    style={{ maxHeight: 700, overflowY: 'auto' }}
                    
                >
                <Spin spinning = {spin}>
                
                 <Form  onFinish={onFinish} form={Editform} encType="multipart/form-data">
                        <div className="grid md:grid-cols-4 gap-3 ">
                            <Item
                                name="fullname"
                                label="Child Full Name"
                                className="flex flex-col"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: 'Please enter your full name' },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter a valid text (letters and spaces only)"
                                  }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Full Name*" onKeyPress={handleKeyPress}/>
                                  {isCapsLockOn && <span style={{ color: 'red' }}>Caps Lock is on! only accept lowercase</span>}
                                  {isShiftOn && <span style={{ color: 'red' }}>Shift key is on!</span>}
                            </Item>
                           
                              
                            <Item
                                name="fathername"
                                label="C/o Name"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: "Please enter your father's name" },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter a valid name (letters and spaces only)"
                                  }
                              ]}
                              >
                            <Input
                               size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                            </Item>

                            <Item
                                name="gender"
                                label="Gender"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {   required : true,message: "Please enter your gender" },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter a gender (letters  only)"
                                  }
                                ]}
                            >
                                <Input size="large" style={{borderRadius:0}} placeholder="Gender"/>
                            </Item>
                                    
                              
                            <Item
                                name="dob"
                                label="DOB"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: "Please enter your dob" },
                                  {
                                    pattern: /^[0-9-]+$/,
                                    message: "Only numbers and '-' are allowed",
                                  }
                              ]}
                            >
                                <Input size="large" style={{borderRadius:0}} maxLength={10} placeholder="01/01/2020"/>
                            </Item>
                            
                            <Item
                                name="houseno"
                                label="House No/Bldg/Apt"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{ message: "Please enter your house no" }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Enter your house no"/>
                            </Item>
                          
                            <Item
                                name="landmark"
                                label="Landmark"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please enter your landmark"}]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Land Mark"/>
                            </Item>

                            <Item
                                name="area"
                                label="Area/Localty"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please enter your area/localty" }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Area/Localty"/>
                            </Item>

                            <Item
                                name="village"
                                label="Village"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  
                                    message: "Please enter your village",
                                    required : true, message: 'Please enter your village'
                                }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Village"/>
                            </Item>
                            
                            <Item
                                name="postoffice"
                                label="Post Office"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  
                                    message: "Please enter your post office",
                                    required : true, message: 'Please enter your post office'
                                  }]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Post Office"/>
                            </Item>

                            <Item
                                name="pincode"
                                label="Pin Code"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {required : true, message: 'Please enter your pin code'},
                                  { pattern: /^\d{6}$/, message: 'Pin number must be 6 digits' }
                                  ]}
                                >
                            <Input size="large" style={{borderRadius:0}} placeholder="Pin Code"/>
                            </Item> 
                    
                            <Item
                                name="district"
                                label="District"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                               rules={[
                                { required : true, message: 'Please enter your district' },
                                {
                                  pattern: /^[A-Za-z\s]+$/,
                                  message: "Please enter text only"
                                }
                              ]}
                            >
                                <Input size="large" style={{borderRadius:0}} placeholder="District" />
                            </Item>

                            <Item
                                name="state"
                                label="State"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  {  required : true, message: 'Please select your state' },
                                  {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Please enter text only"
                                  }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="State"/>
                            </Item>

                            <Item
                                name="mobile"
                                label="Mobile Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  { message: 'Please enter your mobile number' },
                                  { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits' }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Mobile Number" />
                            </Item>

                            <Item
                                name="email"
                                label="Email (Optional)"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                  { message: 'Please enter your email' },
                                  { type: 'email', message: 'Invalid email address' }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Email"/>
                            </Item>
                            <Item name="hoftype"
                                label="Select Hof Type"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please select hof type' }]}
                              
                            >
                              <Select onChange={onType} >
                                <Select.Option value="hfather">Father</Select.Option>
                                <Select.Option value="hmother">Mother</Select.Option>
                                <Select.Option value="hguardian">guardian</Select.Option>
                              </Select>
                            </Item>
                            {
                              (() => {
                              if (type === "hfather") {
                                  return(
                                    <Item
                                        name="hfather"
                                        label="Father"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{ 
                                            required : true, message: 'Please enter father name'
                                         }]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Father Name"/>
                                    </Item>
                                  )
                              } 
                              else if (type === "hmother")
                                    return(
                                      <Item
                                          name="hmother"
                                          label="mother"
                                          wrapperCol={{span: 24}} labelCol={{span:24}}
                                          rules={[{ required : true, message: 'Please enter mother'}]}
                                      >
                                          <Input size="large" style={{borderRadius:0}} placeholder="Mother"/>
                                      </Item>
                                    )
                              else if (type === "hguardian")
                                  return(
                                    <Item
                                        name="hguardian"
                                        label="Guardian"
                                        wrapperCol={{span: 24}} labelCol={{span:24}}
                                        rules={[{  
                                          message: "Please enter guardian name",
                                          required : true, message: 'Please enter guardian name'
                                         }
                                        ]}
                                    >
                                        <Input size="large" style={{borderRadius:0}} placeholder="Guardian name"/>
                                    </Item>
                                  )
                              
                              })()
                          }
                           
                            
                            <Item
                                name="aadhaarnumber"
                                label="Father/Mother/Guardian Aadhaar Number"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                {required : true, message: 'Please enter your Aadhaar number' },
                                { pattern: /^\d{12}$/, message: 'Aadhaar number must be 12 digits' }
                                ]}
                                >
                                <Input size="large" style={{borderRadius:0}} placeholder="Enter Aadhaar Number"/>
                            </Item>

                            <div className="flex md:flex-row flex-col">

                            <Item
                                label="Photo"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                onChange={(e)=>onPhotoChange(e.target)}
                                className="md:mr-6" 
                                rules={[{  message: "Please upload photo" }]}
                              >
                                <Upload multiple={false}>
                                    <Button size="large" className="w-fit" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Item>
                            
                            <Item
                                name="photoValue"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please upload photos" }]}
                                >
                                <Input hidden placeholder="photo"  />
                            </Item>  
                             <Item
                                label="Document"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                onChange={(e)=>onPoiChange(e.target)}
                                rules={[{  message: "Please upload poi" }]}
                                >
                                <Upload multiple={false}>
                                    <Button size="large" className="w-fit" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Item>
                            
                            <Item
                                name="poivalues"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{message: "Please upload poi`s " }]}
                                >
                                <Input hidden placeholder="Document"  />
                            </Item>  
                          
                            </div>
            
                            <Item
                                name="dateApply"
                                label="Date of Apply"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                >
                                <Input  disabled size="large" className="w-28 cursor-not-allowed"  style={{borderRadius:0}}/>
                                
                            </Item>

                    </div>
                    

                    <Item>
                        <Button type="primary" htmlType="submit" className=" bg-indigo-700  text-white"> Submit</Button>
                    </Item>
                 </Form>
                </Spin> 
            
                </Modal>
            </div>
      
        </Layout>
    )
}

export default ChildcHistry;