import { Button } from "antd"
import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <div className=" flex flex-col justify-center items-center min-h-screen bg-yellow-50 gap-y-4">
      <h1 className="text-5xl font-bold">404</h1>
     <h1 className="text-slate-800 text-3xl font-bold">!Oops | Page Not Found </h1>
     <Button className="text-xl h-fit">
      <Link to="/">Go back</Link>
     </Button>
      
    </div>
  )
}

export default NotFound
