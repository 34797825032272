import { createContext ,useContext ,useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import useSWR,{mutate} from "swr";
axios.defaults.baseURL = process.env.REACT_APP_API

const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}

const ChildProvider = createContext();

export const ChildContext = ({children})=>{
    const [enrollUser , setEnrollUser] = useState()
    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("auth"))
        data&&data&&setEnrollUser(data&&data.data.email)
       },[])

       
    
    const [status , setStatus] = useState("");
    const { data:childData, error, isLoading } = useSWR(`/child/set-finger?action=${status}&&enrolluser=${enrollUser&&enrollUser}`, fetcher)
    return (
     <ChildProvider.Provider value={[status , setStatus , childData ,mutate ,isLoading]}>
        {
            children
        }
     </ChildProvider.Provider>
   )
}


export const useChildContext = () => useContext(ChildProvider)



