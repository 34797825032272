import { useState, useEffect } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import SubAdminSpinner from "./subadmin-spinner";
axios.defaults.baseURL = process.env.REACT_APP_API

export const SubAdminRoute = () => { // Renamed to start with uppercase letter
  const [ok, setOk] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subAdminAuth , setSubAdminAuth] = useState();

  
  useEffect(() => {
   
    const data = JSON.parse(localStorage.getItem("subadminAuth"))
  
    if(data){
      setSubAdminAuth(data)
    }
    
    const authCheck = async () => {
      try {
        const { data } = await axios.get("/private-user/sub-admin", {
          headers: {
            Authorization: subAdminAuth?.token,
            role : "subadmin"
          },
        });
        if (data.success) {
          setOk(true);
        }
      } catch (error) {
        setOk(false);
        setError(error.message || "An error occurred");

      } finally {
        setLoading(false);
      }
    };
  

 subAdminAuth && subAdminAuth && authCheck()

  }, [subAdminAuth && subAdminAuth.token]);
  return ok ? <Outlet /> : <SubAdminSpinner />;
};

