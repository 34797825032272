 import { createContext, useContext } from "react"
import { useState ,useEffect } from "react"

  const subAdminProvider = createContext();

  export const AuthSubAdmin =  ({children})=>{
   const [subAdminAuth ,setSubAdminAuth] = useState({
    subAdminAuth : false,
    token : "",
    data : ""
   })

   useEffect(()=>{
    const data =  JSON.parse(localStorage.getItem("subadminAuth"))
     data&&data && setSubAdminAuth({
        subAdminAuth : data.success,
        token : data.token,
        data : data.data
     })
   },[])


   return (
    <subAdminProvider.Provider value={[subAdminAuth ,setSubAdminAuth]}>
        {
            children
        }
    </subAdminProvider.Provider>
   )
  }

  export const useSubAdminAuth = ()=>useContext(subAdminProvider)

