import { useState, useEffect } from "react";
import { useAuth } from "../context/auth";
import axios from "axios";
import { Outlet } from "react-router-dom";
import Spinner from "./spinner";

export const PrivateRoute = () => {
  const [auth, setAuth] = useAuth();
  const [ok, setOk] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const authCheck = async () => {
      try {
        const { data } = await axios.get("/private-user", {
          headers: {
            Authorization: auth.token,
            role : "user"
          },
        });
      
        if (data.success) {
          setOk(true);
        }
      } catch (error) {
        setOk(false);
        setError(error.message || "An error occurred");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (auth && auth.token) {
      authCheck();
    }

    return () => {
      // Cleanup function if needed
    };
  }, [auth.token]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    // Handle error state (e.g., redirect to the login page)
    return <div>Error: {error}</div>;
  }

  return ok ? <Outlet /> : <Spinner />;
};
