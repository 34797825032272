import { useState,useEffect } from "react";
import { Form, Input,Spin,Button } from "antd";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
axios.defaults.baseURL = process.env.REACT_APP_API

const {Item} = Form

const Register = ()=>{
    const [loader,setLoader] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [spin,setSpin] = useState(false)
     const [registerForm] = Form.useForm()
     const navigate = useNavigate()
    const onFinish =async (values)=>{
        try {
            setSpin(true)
       const {data} =  await axios.post("/auth/signup" ,values);
         if(data.success){
          toast.success(<p className="capitalize">{data.message}</p>)
          registerForm.resetFields()
         setTimeout(()=> navigate("/"),2000)

         }
        } catch (error) {
            toast.error(<p className="capitalize">{error.response.data.message} </p>);
        }
        finally {
            setSpin(false)
        }
    }
    useEffect(()=>{
        let releaseTimer = setTimeout(()=>setLoader(false),2000)
        return ()=>{
         clearTimeout(releaseTimer)
        }
     },[])
     if(loader) return (
         <div className="min-h-screen flex items-center justify-center">
             <Spin size="large" />
         </div>
     )
    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <ToastContainer />
            <div className="bg-white rounded-lg border p-8 flex flex-col gap-y-4  md:w-3/12 w-12/12 animate__animated animate__zoomIn animate_faster">
                <div className="flex justify-center items-center ">
                    <h className="text-2xl font-semibold">Registration</h>
                </div>
               <Spin spinning={spin}>
                    <Form onFinish={onFinish} form={registerForm}>
                        <Item 
                            name="fullname"
                            rules={[
                                {required:true,message: 'Fullname  is required'}
                            ]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Fullname*" />
                        </Item>
                        <Item 
                            name="mobile"
                            rules={[
                                {required:true,message: 'Mobile number is required'},
                                { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits' }
                            ]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Mobile number*" />
                        </Item>
                        <Item 
                            name="email"
                            rules={[
                                {required:true,message: 'Email is required'},
                                {type: 'email', message: 'The input is not valid E-mail!'}
                            ]}
                            >
                            <Input size="large" style={{borderRadius:0}} placeholder="Email*" />
                        </Item>
                        <Item 
                            name="password"
                            rules={[
                                {required:true,message: 'Password is required'}
                            ]}
                            >
                            <Input.Password
                                size="large"
                                style={{borderRadius:0}}
                                placeholder="*********"
                                visibilityToggle={{
                                    visible: passwordVisible,
                                    onVisibleChange: setPasswordVisible,
                                    }}
                            />
                        </Item>
                        <Item>
                            <Button
                                htmlType="submit" 
                                size="large"
                                style={{borderRadius:0}} className="w-full bg-indigo-900 text-white" >Register Now</Button>
                        </Item>
                    
                    </Form>
                </Spin>
            </div>
        </div>
    )
}

export default Register;