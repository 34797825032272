import { useState } from "react";
import { Select,Button,Modal,Form,Input,Spin,Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import Layout from "../../layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR,{mutate} from "swr";


axios.defaults.baseURL =  process.env.REACT_APP_API

const {Item} = Form
const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}


const AdminChildData = ()=>{
    const [Editform] = Form.useForm()
    const [filter , setFilter] = useState("")
    const [status,setStatus] = useState("")
    const [spin ,setSpin] = useState(false);
    const [value , setValue] = useState();
    const [model,setModel] = useState(false);
    const [edit , setEdit] = useState({});
    const [updatet,setUpdatet] = useState("")
    const[aadhaarnumber,setAadhaarnumber] = useState("")
    const [base64 , setBase64] = useState("")
    const [basephoto, setBasephoto] = useState("")
    const { data:childData, error, isLoading } = useSWR(`/child/set-finger/admin?filter=${JSON.stringify(filter&&filter)}`, fetcher)
    const navigate = useNavigate()
    


   const onStateChange = (state)=>{
       setFilter(state);
    }

    const onFinish = async (value)=>{
        try {
            await axios.put(`/child/set-finger/admin/${edit.id}`,value)
            toast.success("Update Success")
            setModel(false)
            mutate("/child/set-finger/admin")

        } catch (error) {
            toast.success("Something went wrong")
           
        }
    }

    const updateData = (values)=>{
        setUpdatet(values.updatetype);
        setAadhaarnumber(values.aadhaarnumber)
        setEdit({id:values.id ,enrollUser : values.enrolluser,updatetype:values.updatetype,aadhaarnumber: values.aadhaarnumber,fullname: values.fullname})
        const status = values.action
        setValue(values)
        setModel(true)
     Editform.setFieldsValue(values)
    }

    

    const preview = (data)=>{
        navigate(`/childdata/${data.aadhaarnumber}/${data.fullname}/${data.email}/${data.mobile}/${data.dob}/${data.gender}/${data.newname}`);
    }

    const downloadPhoto = async (aadhar, fullnam) => {
        const fullname = fullnam.replace(/\s/g, '')
        const aadhaar = aadhar+'-'+fullname
        try {
            const response = await axios.get(`/child/preview/download/photo?aadhar=${aadhaar}`);
            const data = response.data;
           const doc =  `data:image/jpeg;base64,${data.document}`
           const a = document.createElement("A");
            a.href = doc;
            a.download = `${fullname}_photo.jpg`;
            a.click();
            toast.success("Download Successfuly")
        } catch (error) {
            toast.error("Something went wrong")
        }
        
    };
    
    const downloadData = async (aadhar, fullnam) => {
        const fullname = fullnam.replace(/\s/g, '')
        const aadhaar = aadhar+'-'+fullname
        try {
            const { data } = await axios.get(`/child/preview/download?aadhar=${aadhaar}`);
            const doc = `data:application/pdf;base64,${data.document}`;
            const a = document.createElement("A");
            a.href = doc;
            a.download = `${fullname}_document.pdf`;
            a.click();
            toast.success("Download Successfuly")
        } catch (error) {
            toast.error("Something went wrong")
        }
    };

    const downloadForm = async (aadhar, fullname, id) => {
        try {
            const { data } = await axios.get(`/child/preview/downloadform?id=${id}`);
            const excludeFields = [
                'id',
                'document',
                'dateofupdate',
                'rejectedmsg',
                'fingerprint',
                'dateofapply',
                'action',
                'enrolluser',
                'hoftype',
            ];
            if (data.data.hoftype === 'hfather') {
                excludeFields.push('hmother', 'hguardian');
            }

            if (data.data.hoftype === 'hmother') {
                excludeFields.push('hfather', 'hguardian');
            }

            if (data.data.hoftype === 'hguardian') {
                excludeFields.push('hmother', 'hfather');
            }
            const filteredData = Object.entries(data.data)
                .filter(([key]) => !excludeFields.includes(key))
                .map(([key, value]) => `${key}: ${value}`)
                .join('\n');
    
            const blob = new Blob([filteredData], { type: 'text/plain' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download', `${fullname}_form.txt`);
            downloadLink.click();

            toast.success("Download Successful");
        } catch (error) {
            toast.error("Something went wrong");
        }
    };
    
    
    const onPhotoChange = (e) => {
        const file = e.files[0];
        if (file.size > 5242880)
          return toast.error("File size should be less than or equal to 5MB");
        if (file.type !== "image/jpeg" && file.type !== "image/png")
          return toast.error("File should be in JPEG or PNG format");
        
        const reader = new FileReader();
        reader.onload = (e) => {
          const basephoto = e.target.result.split(',')[1];
          setBasephoto(basephoto);
          const photoValues = {
            slipValue: basephoto
          };
          Editform.setFieldsValue(photoValues);
        };
        reader.readAsDataURL(file);
      };

    return (
        <Layout>
            <div className="flex md:float-right md:w-[200px] justify-end items-center gap-2 ">   
                <Select className="w-full" value={filter} onChange={onStateChange} >
                    <Select.Option value="">All State</Select.Option>
                    <Select.Option value="assam">Assam</Select.Option>
                    <Select.Option value="westbengal">West Bengal</Select.Option>
                    <Select.Option value="jharkhand">Jharkhand</Select.Option>
                    <Select.Option value="bihar">Bihar</Select.Option>
                    <Select.Option value="uttarpardesh">Up</Select.Option>
                </Select>
            </div>
           
            <table className="w-full border">
        <caption className="font-semiBolad md:text-2xl text-xl mb-4">Child Enroll Pending Data</caption>
            <thead>
            <tr>
                    <th className="border py-3 ">S.no</th>
                    <th className="border py-3 ">Fullname</th>
                    <th className="border py-3 max-md:hidden">Fathername</th>
                    <th className="border py-3 max-md:hidden">State</th>
                    <th className="border py-3 max-md:hidden">District</th>
                    <th className="border py-3 max-md:hidden">Aadhaarnumber</th>
                    <th className="border py-3 max-md:hidden">Mobilenumber</th>
                    <th className="border py-3 max-md:hidden">Email</th>
                    <th className="border py-3 max-md:hidden">Status</th>
                    <th className="border py-3 max-md:hidden">Created on</th>
                    <th className="border py-3 ">Action</th>
                    <th className="border py-3 max-md:hidden">Photo</th>
                    <th className="border py-3 max-md:hidden">Document</th>
                    <th className="border py-3 max-md:hidden">Form</th>
                </tr>
           
            </thead>
            <tbody>
              {
                childData && childData.map((data , index)=>(
                 <tr key={index} className="text-center">
                    <td className='py-2 '>{(index)+1}</td>
                    <td className="capitalize cursor-pointer" onClick={()=>preview(data)}>{data.fullname}</td>
                    <td className="capitalize max-md:hidden ">{data.fathername}</td>
                    <td className='max-md:hidden capitalize'>{data.state}</td>
                    <td className='max-md:hidden capitalize'> {data.district}</td>
                    <td className='max-md:hidden'> {data.aadhaarnumber}</td>
                    <td className='max-md:hidden'> {data.mobile}</td>
                    <td className='max-md:hidden lowercase'> {data.email}</td>
                    <td className='max-md:hidden capitalize'> {data.action}</td>
                    <td className="max-md:hidden">{ new Date(data.dateofapply).toLocaleDateString() } {new Date(data.dateofapply).toLocaleTimeString()}</td>
                    <td className="lowercase">
                     <button  onClick={()=>updateData(data)}><i class='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'  ></i> </button>               
                    </td>
                    <td className='max-md:hidden'>
                        <button  onClick={()=>downloadPhoto(data.aadhaarnumber,data.fullname)}><i class='bx bx-down-arrow-circle bx-tada-hover text-2xl cursor-pointer'  ></i> </button>
                    </td>
                    <td className='max-md:hidden'>
                        <button  onClick={()=>downloadData(data.aadhaarnumber,data.fullname)}><i class='bx bx-down-arrow-circle bx-tada-hover text-2xl cursor-pointer'  ></i> </button>
                    </td>   
                    <td className='max-md:hidden'>
                        <button  onClick={()=>downloadForm(data.aadhaarnumber,data.fullname,data.id)}><i class='bx bx-down-arrow-circle bx-tada-hover text-2xl cursor-pointer'></i> </button>
                    </td>   
                    
                 </tr>
                ))
               }
            </tbody>
        </table>

            <Modal
                title="Child Status Update"
                centered
                open={model}
                onCancel={() => setModel(false)}
                width={300}
                footer ={null}
                style={{ maxHeight: 700, overflowY: 'auto' }}>

                    <Spin spinning = {spin}>
                    <Form  encType="multipart/form-data" form={Editform} onFinish={onFinish} initialValues={{"dateofupdate" :  new Date(),"updatetype" : updatet,"aadhaarnumber": aadhaarnumber}}>
                            <div className="grid md:grid-cols-2 gap-3 ">
                                <Item
                                    name="fullname"
                                    label="Full Name"
                                    className="flex flex-col"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    rules={[{  required : true, message: 'Please enter your full name' }]}>
                                    <Input style={{borderRadius:0}} placeholder="Fullname*" />
                                </Item>

                                <Item
                                    name="action"
                                    label="Status"
                                    className="flex flex-col"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    size="large"
                                >
                                <Select className="w-full" value={status}>
                                    <Select.Option value="success"> Success</Select.Option>
                                    <Select.Option value="rejected">Rejected</Select.Option>
                                </Select>
                                </Item>

                                <Item 
                                    name="reason"
                                    label="rejected reason"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="Rejected Reason" />
                                </Item>

                            <Item
                                label="Photo"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                onChange={(e)=>onPhotoChange(e.target)}
                                className="md:mr-6" 
                                rules={[{  message: "Please upload poi" }]}
                            >
                                <Upload multiple={false}>
                                    <Button size="large" className="w-fit" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Item>
                            
                            <Item
                                name="slipValue"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  message: "Please upload poi" }]}
                                >
                                <Input hidden placeholder="photo"  />
                            </Item>  
                                <Item
                                    name="dateofupdate"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input hidden size="large"  />
                                    
                                </Item>
                                <Item 
                                    name="aadhaarnumber"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="aadhaarnumber" hidden/>
                                </Item>
                           
                                <Item 
                                    name="enrolluser"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="Enroll user" hidden/>
                                </Item>
                            
                        </div>
                        <Item className="flex justify-center items-center">
                            <Button type="primary" htmlType="submit" className=" bg-indigo-700 float-right  text-white "> Submit</Button>
                        </Item>
                    </Form>
                    </Spin> 
                
            </Modal>
        </Layout>
    )
}

export default AdminChildData