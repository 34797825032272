import React, { useState } from 'react';
import { Skeleton,Form,Input,Spin,Button,Modal,Select } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { toast } from "react-toastify"
import Layout from '../layout';
import useSWR , {mutate} from 'swr';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API

const {Item} = Form

const fetcher = async (url)=>{
   try {
     const {data} = await axios.get(url);
     return data;
   } catch (error) {
    throw new Error(error)
   }
}

const EntryToken = () => {
    const [status,setStatus] = useState("")
    const [spin ,setSpin] = useState(false);
    const [value , setValue] = useState({});
    const [model,setModel] = useState(false)
    const [expandedRow, setExpandedRow] = useState(null); 
    
    const {data , error , isloading} = useSWR (`/user-wallet/admin?action=${status}` , fetcher)
    
    const onStatusChange = async (id , value)=>{
        try {
             await axios.get(`/userwallet`)
             mutate("/userwallet")
            
        } catch (error) {
            console.log(error);
        }
    }

    const updateData = (values)=>{
        const status = values.action
        
        if(status === 'success')
        return  toast.info("! According to Apply time with in 30 Minutes Editable")
        setValue(values)
        setModel(true)
     Editform.setFieldsValue(values)
    }

    const onStatusFilter =(value)=>{
      setStatus(value) 
    }

    const onFinish = async (values)=>{
        console.log(values);
        try {
          setSpin(true) 
          const res = await axios.put(`user-wallet/admin/${value.id}`,values)
          console.log(res);
          toast.success("Data Successfully Updated")
          setModel(false)
          mutate(`/user-wallet/admin?action=${status}`)
        } catch (error) {
           toast.error("Something went wrong")
          
        }
        finally {
          setSpin(false)
        }
       }

    const [Editform] = Form.useForm()

  return (
    <Layout>
        
            <div className="flex md:float-right md:w-[200px] justify-end items-center gap-2 ">
                <Select className="w-full" value={status} onChange={onStatusFilter}>
                    <Select.Option value=""> All data</Select.Option>
                    <Select.Option value="pending"> Pending</Select.Option>
                    <Select.Option value="success"> Success</Select.Option>
                    <Select.Option value="rejected">Rejected</Select.Option>
                </Select>
            </div>
       {

        isloading && <Skeleton active /> || <table className="w-full border">
        <caption className="font-semiBolad md:text-2xl text-xl mb-4">User Balance Update Data</caption>
            <thead>
                <tr>
                    <th className="border py-3">S.no</th>
                    <th className="border py-3 ">Fullname</th>
                    <th className="border py-3">Email Id</th>
                    <th className="border py-3">Wallet</th>
                    <th className="border py-3">Transaction id</th>
                    <th className="border py-3">Status</th>
                    <th className="border py-3">Created on</th>
                    <th className="border py-3">Update on</th>
                    <th className="border py-3">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    data && data.result.map((user , index)=>(
                    <tr className=" odd:bg-[#c8d7f992]  even:bg-pink-100 text-center" key={index}>
                        <td className='py-2'onClick={() => setExpandedRow(expandedRow === index ? null : index)} style={{ cursor: 'pointer' }}>{(index) + 1}</td>
                        <td className="capitalize">{user.fullname}</td>
                        <td className="capitalize ">{user.email}</td>
                        <td className=''>₹ {user.amount}</td>
                        <td className=''> {user.trannumber}</td>
                        <td className='capitalize text-start'>{user.action}</td>
                        <td>{ new Date(user.dateofapply).toLocaleDateString() } {new Date(user.dateofapply).toLocaleTimeString()}</td>
                        <td>{ new Date(user.dateofupdate).toLocaleDateString() } {new Date(user.dateofupdate).toLocaleTimeString()}</td>
                        <td className="lowercase">
                            {
                                (() => {
                                if (user.action === "pending") {
                                    return (
                                    
                                        <button onClick={()=>updateData(user)}><i class='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'  ></i> </button>
                                    );
                                } 
                                else if (user.action === "success")
                                        return(
                                        <i class='bx bx-check text-green-600 text-2xl'></i>
                                        )
                                else if (user.action === "rejected")
                                    return(
                                    <i class='bx bx-x text-red-600 text-2xl'></i>
                                    )
                                
                                })()
                            }
                        </td>
                    </tr>

                    ))
                }   
            </tbody>
        </table>
        }

                <Modal
                    title="User Wallet Update Form"
                    centered
                    open={model}
                    onCancel={() => setModel(false)}
                    width={600}
                    footer ={null}
                    style={{ maxHeight: 700, overflowY: 'auto' }}
                >
                <Spin spinning = {spin}>
                <Form  encType="multipart/form-data" form={Editform} onFinish={onFinish} initialValues={{"dateofupdate" :  new Date()}}>
                        <div className="grid md:grid-cols-2 gap-3 ">
                            <Item
                                name="fullname"
                                label="Full Name"
                                className="flex flex-col"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{  required : true, message: 'Please enter your full name' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="Fullname*" />
                            </Item>

                            <Item
                                name="email"
                                label="Email"
                                className="flex flex-col"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[{ message: 'Please enter your email' }]}>
                                <Input size="large" style={{borderRadius:0}} placeholder="email" />
                            </Item>

                            <Item
                              name="trannumber"
                              label="Transaction Number"
                              wrapperCol={{span: 24}} labelCol={{span:24}}
                              rules={[{  required : true, message: 'Please enter your transaction' }]}>
                              <Input  style={{borderRadius:0}} placeholder="Lest 5 digit of Transaction*" />
                            </Item>

                            <Item 
                                name="amount"
                                label="Amount"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                rules={[
                                    {  required : true, message: 'Please type amount' }
                                
                                    ]}>
                                <Input type="number" />
                            </Item>

                            <Item
                                name="action"
                                label="Status"
                            >
                            <Select className="w-full" value={status}>
                                <Select.Option value={1}> Success</Select.Option>
                                <Select.Option value={0}>Rejected</Select.Option>
                            </Select>
                            </Item>
                            <Item
                                name="dateofupdate"
                                wrapperCol={{span: 24}} labelCol={{span:24}}
                                >
                                <Input hidden size="large"  />
                                
                            </Item>
                           
                    </div>
                    <Item>
                        <Button type="primary" htmlType="submit" className=" bg-indigo-700 float-right  text-white"> Submit</Button>
                    </Item>
                 </Form>
                </Spin> 
            
                </Modal>
        
    </Layout>
  );
};

export default EntryToken;