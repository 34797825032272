import { createContext ,useContext ,useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import useSWR,{mutate} from "swr";
axios.defaults.baseURL = process.env.REACT_APP_API

const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}

const MobileProvider = createContext();

export const MobileContext = ({children})=>{
    const [enrollUser , setEnrollUser] = useState()
    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem("auth"))
        data && setEnrollUser(data.data.email);
       },[])

       
    
    const [status , setStatus] = useState("");
    const { data:mobileData, error, isLoading } = useSWR(`/mobile/set-finger?action=${status}&&enrolluser=${enrollUser&&enrollUser}`, fetcher)
    return (
     <MobileProvider.Provider value={[status , setStatus , mobileData ,mutate ,isLoading]}>
        {
            children
        }
     </MobileProvider.Provider>
   )
}


export const useMobileContext = () => useContext(MobileProvider)



