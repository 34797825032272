import { useState } from "react";
import { Select,Button,Input } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import Layout from "../layout";
import axios from "axios";
import useSWR,{mutate} from "swr";
axios.defaults.baseURL =  process.env.REACT_APP_API

 const {Search} = Input
const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}
const MobileDataHistory = ()=>{
    const [status,setStatus] = useState("")
    const [filteredData, setFilteredData] = useState(null);

    const { data:mobileData, error, isLoading } = useSWR(`/mobile/set-finger/admin/mobile-history?action=${status&&status}`, fetcher)

    const onStatusFilter =(status)=>{
        setStatus(status)
        mutate(`/mobile/set-finger/admin/mobile-history?action=${status}`)
    }
    const onSearch = (value) => {
        if (!value.trim()) {
            setFilteredData(null);
            return;
        }
        const filteredData = mobileData.filter(data => String(data.aadhaarNumber).includes(value));
        setFilteredData(filteredData);
       
    }
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(e.target.value);
        }
    };
    return (
        <Layout>
            <div className="flex md:float-right gap-2 w-[150]">
                <Search placeholder="Search " onSearch={onSearch} onPressEnter={handleKeyPress}  enterButton />
            </div>
        
            <table className="w-full border">
        <caption className="font-semiBolad text-2xl mb-4">Mobile Data Working Complete History</caption>
            <thead>
                <tr>
                    <th className="border py-3">S.no</th>
                    <th className="border py-3">Fullname</th>
                    <th className="border py-3">Fathername</th>
                    <th className="border py-3">State</th>
                    <th className="border py-3">District</th>
                    <th className="border py-3">Aadhaarnumber</th>
                    <th className="border py-3">Mobilenumber</th>
                    <th className="border py-3">Email</th>
                    <th className="border py-3">
                        Status
                        <Select className="w-full" value={status} onChange={onStatusFilter}>
                        <Select.Option value=""> All data</Select.Option>
                        <Select.Option value="pending"> Pending</Select.Option>
                        <Select.Option value="success"> Success</Select.Option>
                        <Select.Option value="rejected">Rejected</Select.Option>
                        </Select>
                    </th>
                    <th className="border py-3">Created on</th>
                    <th className="border py-">Update on</th>
                    
                </tr>
            </thead>
            <tbody>
                 {
                    (filteredData || mobileData) && (filteredData || mobileData).map((data,index)=>(
                        <tr key={index}>
                        <td className='py-2'>{(index)+1}</td>
                        <td className="capitalize cursor-pointer ">{data.fullname}</td>
                        <td className="capitalize max-md:hidden ">{data.fatherName}</td>
                        <td className='max-md:hidden'>{data.state}</td>
                        <td className='max-md:hidden'> {data.district}</td>
                        <td className='max-md:hidden'> {data.aadhaarNumber}</td>
                        <td className='max-md:hidden'> {data.mobileNumber}</td>
                        <td className='max-md:hidden lowercase'> {data.email}</td>
                        <td className='max-md:hidden'> {data.action}</td>
                        <td className="max-md:hidden">{ new Date(data.dateofapply).toLocaleDateString() } {new Date(data.dateofapply).toLocaleTimeString()}</td>
                        <td className="max-md:hidden">{ new Date(data.dateofupdate).toLocaleDateString() } {new Date(data.dateofupdate).toLocaleTimeString()}</td>
                            
                        
                     </tr>
                    ))
                 }
            </tbody>
        </table>
        </Layout>
    )
}

export default MobileDataHistory;