import Layout from './layout';
import { Form ,Button, Input,Spin} from "antd";
import {UserOutlined,LockOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,useLocation } from "react-router-dom";
axios.defaults.baseURL = process.env.REACT_APP_API


const {Item} = Form

const AdminDashboard =()=>{
    
    return(
        <Layout>
            <h1>Welcome to admin</h1>
        </Layout>
       
    )
}

export default AdminDashboard;