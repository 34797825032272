import { Spin } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AdminSpinner = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
      if (count === 1) {
        navigate("/adminlogin");
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval); // Clear the interval on component unmount

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);



  return (
    <div className="min-h-screen w-full flex flex-col justify-center items-center">
      <Spin spinning={true} size="large" />
    
       <h1 className="text-xl"> Redircting With In {count} seconds</h1>
     
    </div>
  );
};

export default AdminSpinner;
