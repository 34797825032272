import { useState } from "react";
import { Select,Button,Modal,Form,Input,Spin } from "antd";
import { toast } from "react-toastify";
import Layout from "../layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR,{mutate} from "swr";
axios.defaults.baseURL =  process.env.REACT_APP_API

const {Item} = Form
const fetcher = async (url)=>{
    try {
        const {data} = await axios.get(url)
        return data
    } 
    catch(error){
        throw new Error(error)
    }
}

const MobileData = ()=>{
    const [filter , setFilter] = useState("")
    const [status,setStatus] = useState("")
    const [spin ,setSpin] = useState(false);
    const [value , setValue] = useState();
    const [model,setModel] = useState(false);
    const [edit , setEdit] = useState({});
    const { data:mobileData, error, isLoading } = useSWR(`/mobile/set-finger/admin?filter=${JSON.stringify(filter&&filter)}`, fetcher)
    const navigate = useNavigate()

   const onStateChange = (state)=>{
       setFilter(state);
    }

    const onFinish = async (value)=>{
        try {
            await axios.put(`/mobile/set-finger/admin/${edit.id}`,value)
            toast.success("Update Success")
            setModel(false)
            mutate("/mobile/set-finger/admin")

        } catch (error) {
            toast.success("Something went wrong")
           
        }
    }

    const updateData = (values)=>{
        setEdit({id:values.id ,enrollUser : values.enrolluser})
        const status = values.action
        setValue(values)
        setModel(true)
     Editform.setFieldsValue(values)
    }

    const [Editform] = Form.useForm()


    const preview = (data)=>{
        navigate(`/mobiledata/${data.aadhaarNumber}/${data.fullname}/${data.email}/${data.mobileNumber}`);
    }

    return (
        <Layout>
            <div className="flex md:float-right md:w-[200px] justify-end items-center gap-2 ">   
                <Select className="w-full" value={filter} onChange={onStateChange} >
                    <Select.Option value="">All State</Select.Option>
                    <Select.Option value="assam">Assam</Select.Option>
                    <Select.Option value="westbengal">West Bengal</Select.Option>
                    <Select.Option value="jharkhand">Jharkhand</Select.Option>
                    <Select.Option value="bihar">Bihar</Select.Option>
                    <Select.Option value="uttarpardesh">Up</Select.Option>
                </Select>
            </div>

            <table className="w-full border">
        <caption className="font-semiBolad md:text-2xl text-xl mb-4">Mobile Number Update Pending Data</caption>
            <thead>
            <tr>
                    <th className="border py-3 ">S.no</th>
                    <th className="border py-3 ">Fullname</th>
                    <th className="border py-3 max-md:hidden">Fathername</th>
                    <th className="border py-3 max-md:hidden">State</th>
                    <th className="border py-3 max-md:hidden">District</th>
                    <th className="border py-3 max-md:hidden">Aadhaarnumber</th>
                    <th className="border py-3 max-md:hidden">Mobilenumber</th>
                    <th className="border py-3 max-md:hidden">Email</th>
                    <th className="border py-3 max-md:hidden">Status</th>
                    <th className="border py-3 max-md:hidden">Created on</th>
                    <th className="border py-3 max-md:hidden">Action</th>
                </tr>
           
            </thead>
            <tbody>
              {
                mobileData && mobileData.map((data , index)=>(
                 <tr key={index} className="text-center">
                    <td className='py-2 '>{(index)+1}</td>
                    <td className="capitalize cursor-pointer" onClick={()=>preview(data)}>{data.fullname}</td>
                    <td className="capitalize max-md:hidden ">{data.fatherName}</td>
                    <td className='max-md:hidden'>{data.state}</td>
                    <td className='max-md:hidden'> {data.district}</td>
                    <td className='max-md:hidden'> {data.aadhaarNumber}</td>
                    <td className='max-md:hidden'> {data.mobileNumber}</td>
                    <td className='max-md:hidden lowercase'> {data.email}</td>
                    <td className='max-md:hidden'> {data.action}</td>
                    <td className="max-md:hidden">{ new Date(data.dateofapply).toLocaleDateString() } {new Date(data.dateofapply).toLocaleTimeString()}</td>
                    <td className="lowercase">
                     <button  onClick={()=>updateData(data)}><i class='bx bxs-edit-alt bx-tada-hover text-2xl cursor-pointer'  ></i> </button>               
                    </td>      
                    
                 </tr>
                ))
               }
            </tbody>
        </table>

            <Modal
                title="User Wallet Update Form"
                centered
                open={model}
                onCancel={() => setModel(false)}
                width={300}
                footer ={null}
                style={{ maxHeight: 700, overflowY: 'auto' }}>

                    <Spin spinning = {spin}>
                    <Form  encType="multipart/form-data" form={Editform} onFinish={onFinish} initialValues={{"dateofupdate" :  new Date()}}>
                            <div className="grid md:grid-cols-2 gap-3 ">
                                <Item
                                    name="fullname"
                                    label="Full Name"
                                    className="flex flex-col"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    rules={[{  required : true, message: 'Please enter your full name' }]}>
                                    <Input style={{borderRadius:0}} placeholder="Fullname*" />
                                </Item>

                                <Item
                                    name="action"
                                    label="Status"
                                    className="flex flex-col"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}
                                    size="large"
                                >
                                <Select className="w-full" value={status}>
                                    <Select.Option value="success"> Success</Select.Option>
                                    <Select.Option value="rejected">Rejected</Select.Option>
                                </Select>
                                </Item>

                                <Item 
                                    name="reason"
                                    label="rejected reason"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="Rejected Reason" />
                                </Item>
                                <Item
                                    name="dateofupdate"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input hidden size="large"  />
                                    
                                </Item>
                                
                                <Item 
                                    name="enrolluser"
                                    className="col-span-1"
                                    wrapperCol={{span: 24}} labelCol={{span:24}}>
                                    <Input size="large" style={{borderRadius:0}} placeholder="Enroll user" hidden/>
                                </Item>
                            
                        </div>
                        <Item className="flex justify-center items-center">
                            <Button type="primary" htmlType="submit" className=" bg-indigo-700 float-right  text-white "> Submit</Button>
                        </Item>
                    </Form>
                    </Spin> 
                
            </Modal>
        </Layout>
    )
}

export default MobileData;
